import React from "react";

import Header from "../../components/Header/Header";
import HeroBlog from "../../components/Hero/HeroBlog";
import ResourcesArticleThree from "../../components/ResourcesContent/SeparatedPosts/ThirdPost";
import Footer from "../../components/Footer/Footer";

const ResourcesArticleThreeScreen = () => {
  return (
    <div>
      <Header />
      <HeroBlog />
      <ResourcesArticleThree />
      <Footer />
    </div>
  );
};

export default ResourcesArticleThreeScreen;