import React from "react";

import "./IndustrialSecurityThreats.css";
import "../../vectors/cybersecVectors.css";
import "../../vectors/industrialVectors.css";

const factoryLineGraph = require("../../img/industriesConsulting/graph/factoryLine.png");
// const fighterJetGraph = require("../../img/industrial/graphs/fighterJet.png");

const IndustrialSecurityThreats = () => {
  return (
    <div className="industrialSecurityThreatsArea">
      <div className="industrialSecurityThreatsLargeExclusive">
        <div className="industrialSecurityThreatsInnerArea">
          <div className="desktopExclusive">
            <div className="industrialSecurityThreatsUpperContainer">
              <div className="industrialTitleContainer">
                <h1>Helping industries safeguard operations</h1>
              </div>
              <div className="industrialGraphContainer">
                <img
                  src={factoryLineGraph}
                  alt={""}
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </div>
          </div>
          <div className="mobileExclusive">
            <div className="industrialSecurityThreatsUpperContainer">
              <div className="industrialGraphContainer">
                <img
                  src={factoryLineGraph}
                  alt={""}
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <div className="industrialTitleContainer">
                <h1>Helping industries safeguard operations</h1>
              </div>
            </div>
          </div>
          <div className="industrialSecurityThreatsMainContainer">
            <div className="industrialSecurityThreatsRow">
              <div className="industrialSecurityThreatsCol">
                <i
                  className="rvb-confidentiality-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Sensitive data protection</h3>
                <p>
                  Industrial companies handle vast amounts of information
                  related to its projects, operations, processes and assets
                  including customer information, financial records,
                  intellectual property, and proprietary business data. Solid
                  cybersecurity measures protect this information from
                  unauthorized access, identity theft, and privacy breaches,
                  ensuring data remains confidential and secure.
                </p>
              </div>
              <div className="industrialSecurityThreatsCol">
                <div className="desktopExclusive">
                  <i
                    className="rvb-boxes-line-alternative-icon"
                    style={{ width: "102px", height: "70px" }}
                  ></i>
                </div>
                <div className="mobileExclusive">
                  <i
                    className="rvb-boxes-line-alternative-icon"
                    style={{ width: "145px important", height: "100px !important", }}
                  ></i>
                </div>
                <h3>Ensuring continuity of operations</h3>
                <p>
                  Effective cybersecurity measures help maintain uninterrupted
                  business operations. By preventing or mitigating the impact of
                  cyber incidents, companies can avoid costly disruptions,
                  downtime, and the associated financial losses. This ensures
                  operational continuity and enables companies to deliver
                  products and services without interruption.
                </p>
              </div>
              <div className="industrialSecurityThreatsCol">
                <i
                  className="rvb-public-trust-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Preservation of company's reputation</h3>
                <p>
                  A successful cyberattack can severely damage a company's
                  reputation and erode customer trust. By combatting
                  cybersecurity threats, organizations demonstrate their
                  commitment to safeguarding customer data and protecting
                  against potential breaches. This fosters a positive
                  reputation, enhancing customer confidence and loyalty.
                </p>
              </div>
              <div className="industrialSecurityThreatsCol">
                <i
                  className="rvb-financial-stability-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Mitigation of financial losses</h3>
                <p>
                  Cyberattacks can lead to significant financial losses,
                  including the costs of incident response, recovery, legal
                  liabilities, and potential regulatory fines. By actively
                  combatting cybersecurity threats, companies can reduce the
                  risk of financial losses associated with cyber incidents and
                  allocate resources more efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustrialSecurityThreats;
