import React from "react";
import { Routes, Route, HashRouter as Router } from "react-router-dom";

import HomeScreen from "./screens/HomeScreen";
import EnergyMgmtScreen from "./screens/EnergyMgmtScreen";
import SecuritySoftwareScreen from "./screens/SecuritySoftwareScreen";
// import ConsultingScreen from "./screens/ConsultingScreen";
import CommercialConsultingScreen from "./screens/CommercialConsultingScreen";
import IndustrialConsultingScreen from "./screens/IndustrialConsultingScreen";
import PublicOrganizationsConsultingScreen from "./screens/PublicOrganizationsConsultingScreen";
import CareerScreen from "./screens/CareerScreen";
import CompanyScreen from "./screens/CompanyScreen";
import ContactScreen from "./screens/ContactScreen";
import ResourcesScreen from "./screens/ResourcesScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";

import ResourcesArticleOneScreen from "./screens/resourceArticles/ResourcesBlog001";
import ResourcesArticleTwoScreen from "./screens/resourceArticles/ResourcesBlog002";
import ResourcesArticleThreeScreen from "./screens/resourceArticles/ResourcesBlog003";
import ResourcesArticleFourScreen from "./screens/resourceArticles/ResourcesBlog004";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

import "./App.css";
import "./fonts/fonts.css";
import "./fonts/fontawesome.css";

import ReactGA from "react-ga";
const TRACKING_ID = "UA-12341234-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <main className="py-3">
        <Routes>
          <Route path="/" element={<HomeScreen />} exact />
          <Route path="/energy-management" element={<EnergyMgmtScreen />} />
          <Route
            path="/industrial-cybersecurity"
            element={<SecuritySoftwareScreen />}
          />
          <Route
            path="/commercial-security-services"
            element={<CommercialConsultingScreen />}
          />
          <Route
            path="/industrial-security-services"
            element={<IndustrialConsultingScreen />}
          />
          <Route
            path="/public-organizations"
            element={<PublicOrganizationsConsultingScreen />}
          />
          <Route path="/resources" element={<ResourcesScreen />} />
          <Route path="/careers" element={<CareerScreen />} />
          <Route path="/company" element={<CompanyScreen />} />
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
          {/* */}
          <Route
            path="/resources/protect-smart-factory"
            element={<ResourcesArticleOneScreen />}
          />
          <Route
            path="/resources/rising-relevance-cybersec"
            element={<ResourcesArticleTwoScreen />}
          />
          <Route
            path="/resources/mitre-ics"
            element={<ResourcesArticleThreeScreen />}
          />
          <Route
            path="/resources/the-human-factor"
            element={<ResourcesArticleFourScreen />}
          />
        </Routes>
      </main>
    </Router>
  );
};

export default App;
