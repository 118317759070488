/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

import AutoPlaySilentVideo from "../AutoplayVideo/AutoplayVideo";

import "./PublicOrganizationsContent.css";

import earthVideo from "../../media/techEarth.mp4";

const PublicOrganizationsContent = () => {
  return (
    <div className="publicOrganizationsContentArea">
      <div className="desktopExclusive">
        <div className="publicOrganizationsContentRow publicOrganizationsContentLargeExclusive">
          <div className="colPublicOrganizationsContentImageContainer">
            <AutoPlaySilentVideo className="planetVideo" video={earthVideo} />
          </div>
          <div
            className="colPublicOrganizationsContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="publicOrganizationsTitleDecorationLine" />
            <h3>New technologies bring new challenges</h3>
            <p>
              Public Organizations around the world are increasingly adopting
              advanced cyber technologies in order to guarantee general
              well-being and improve the quality of life of their citizens.
              <br></br>
              <br></br>
              Nevertheless, cyber interconnectivy expands the potential attack
              surface which, if operating under inappropriated security measures
              could expose society to severe risks never faced before.
            </p>
          </div>
        </div>
      </div>
      <div className="mobileExclusive">
        <div className="publicOrganizationsContentRow">
          <div
            className="colPublicOrganizationsContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="publicOrganizationsTitleDecorationLine" />
            <h3>New technologies bring new challenges</h3>
            <p>
              Public Organizations around the world are increasingly adopting
              advanced cyber technologies in order to guarantee general
              well-being and improve the quality of life of their citizens.
              <br></br>
              <br></br>
              Nevertheless, cyber interconnectivy expands the potential attack
              surface which, if operating under inappropriated security measures
              could expose society to severe risks never faced before.
            </p>
          </div>
          <div className="colPublicOrganizationsContentImageContainer">
            <div className="colPublicOrganizationsContentImageContainer">
              <AutoPlaySilentVideo className="planetVideo" video={earthVideo} />
            </div>
          </div>
        </div>
      </div>
      <div className="publicOrganizationsContentRow publicOrganizationsContentLargeExclusive">
        <div
          className="colPublicOrganizationsContentBlockContainer"
          style={{ paddingRight: "80px" }}
        >
          <div className="publicOrganizationsTitleDecorationLine" />
          <h3>Cyber becomes physical</h3>
          <p>
            Government agencies and other public organizations face several
            cybersecurity concerns due to their key role in society and the
            sensitive nature of the data they handle.
            <br></br>
            <br></br>A weak cybersecurity framework and compliance in their
            networks can have significant implications to the civilian
            population such as increased criminal activity caused from identity
            theft and personal data exfiltration as well as economic impact
            caused by the downtime due to essential service disruption, among
            others.
          </p>
        </div>
        <div className="colPublicOrganizationsContentImageContainer publicOrganizationsBlockTwoBg"></div>
      </div>
      <div className="desktopExclusive">
        <div className="publicOrganizationsContentRow publicOrganizationsContentLargeExclusive">
          <div className="colPublicOrganizationsContentImageContainer publicOrganizationsBlockThreeBg"></div>
          <div
            className="colPublicOrganizationsContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="publicOrganizationsTitleDecorationLine" />
            <h3>Proactive approach</h3>
            <p>
              Cybersecurity is an ongoing process that starts with the
              implementation of risk prevention and mitigation measures
              accompanied by a security awareness traning program.
              <br></br>
              <br></br>
              Our team of experienced consultants is at your disposal to help
              you assess your current security measures, identify
              vulnerabilities, and develop a comprehensive plan to safeguard
              against cyber threats.
            </p>
          </div>
        </div>
      </div>
      <div className="mobileExclusive">
        <div className="publicOrganizationsContentRow">
          <div
            className="colPublicOrganizationsContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="publicOrganizationsTitleDecorationLine" />
            <h3>Proactive approach</h3>
            <p>
              Cybersecurity is an ongoing process that starts with the
              implementation of risk prevention and mitigation measures
              accompanied by a security awareness traning program.
              <br></br>
              <br></br>
              Our team of experienced consultants is at your disposal to help
              you assess your current security measures, identify
              vulnerabilities, and develop a comprehensive plan to safeguard
              against cyber threats.
            </p>
          </div>
          <div className="colPublicOrganizationsContentImageContainer publicOrganizationsBlockThreeBg"></div>
        </div>
      </div>
    </div>
  );
};

export default PublicOrganizationsContent;
