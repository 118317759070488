/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

import "./CompanyContent.css";
import "../Footer/Footer.css";

const CompanyContent = () => {
  return (
    <div className="companyContentArea">
      <div className="desktopExclusive">
        <div className="companyContentRow companyContentLargeExclusive">
          <div className="colCompanyContentImageContainer blockOneBg"></div>
          <div
            className="colCompanyContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="titleDecorationLine" />
            <h3>Company in brief</h3>
            <p>
              RVBionics is a technology company, dedicated to develop industrial
              software solutions and provide top-notch cybersecurity for a
              variety of industries.
              <br></br>
              <br></br>
              Our mission is to drive digital transformation in order to operate
              more efficiently, and securely by leveraging the latest
              technological advances targeting OT/ICS/IIoT systems and networks.
            </p>
          </div>
        </div>
      </div>
      <div className="mobileExclusive">
        <div className="companyContentRow">
          <div
            className="colCompanyContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="titleDecorationLine" />
            <h3>Company in brief</h3>
            <p>
              RVBionics is a technology company, dedicated to develop industrial
              software solutions and provide top-notch cybersecurity for a
              variety of industries.
              <br></br>
              <br></br>
              Our mission is to drive digital transformation in order to operate
              more efficiently, and securely by leveraging the latest
              technological advances targeting OT/ICS/IIoT systems and networks.
            </p>
          </div>
          <div className="colCompanyContentImageContainer blockOneBg"></div>
        </div>
      </div>
      <div className="companyContentRow companyContentLargeExclusive">
        <div
          className="colCompanyContentBlockContainer"
          style={{ paddingRight: "80px" }}
        >
          <div className="titleDecorationLine" />
          <h3>Creating the future, digitally</h3>
          <p>
            Our focus on Industrial Software and Cybersecurity aligns with the
            needs of Industry 4.0 and digital transformation. As businesses are
            increasingly adopting digital technologies to improve their
            operations, they are also becoming more vulnerable to cyber threats.
            <br></br>
            <br></br>
            Our goal is to help organizations navigate this new era by providing
            the tools and solutions they need to stay ahead of the curve.
          </p>
        </div>
        <div className="colCompanyContentImageContainer blockTwoBg"></div>
      </div>
      <div className="desktopExclusive">
        <div className="companyContentRow companyContentLargeExclusive">
          <div className="colCompanyContentImageContainer">
            <div className="embeddedMap">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.1374647456732!2d-70.59942602395702!3d-33.39357769460245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c8cab117d2cb%3A0x2cd237ce028dc14b!2sAv.%20Nueva%20Costanera%204229%2C%20Of%20203%2C%207630156%20Vitacura%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1sen!2sar!4v1683089495147!5m2!1sen!2sar"
                style={{ border: "0", width: "100%", height: "364px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div
            className="colCompanyContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="titleDecorationLine" />
            <h3 style={{ lineHeight: "30%" }}>Main Office</h3>
            <p>
              Based in Santiago, Chile. The core of our business relies on
              driving digital transformation within the region.
            </p>
            <div className="contactCol">
              <div className="locationContainer">
                <div className="locationElement">
                  <li>Av. Nueva Costanera 4229 of. 203.</li>
                  <li>Postal Code 7630156</li>
                  <li>Vitacura, Región Metropolitana.</li>
                  <li>Chile</li>
                  <li>
                    <a href="tel:+56951587794">
                      <b>+56 9 5158 7794</b>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:contacto@rvbionics.com">
                      <b>contacto@rvbionics.com</b>
                    </a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobileExclusive">
        <div className="companyContentRow">
          <div
            className="colCompanyContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="titleDecorationLine" />
            <h3 style={{ lineHeight: "30%" }}>Main Office</h3>
            <p>
              Based in Santiago, Chile. The core of our business relies on
              driving digital transformation within the region.
            </p>
            <div className="contactCol" style={{textAlign: "center"}}>
              <div className="locationContainer">
                <div className="locationElement">
                  <li>Av. Nueva Costanera 4229 of. 203.</li>
                  <li>Postal Code 7630156</li>
                  <li>Vitacura, Región Metropolitana.</li>
                  <li>Chile</li>
                  <li>
                    <a href="tel:+56951587794">
                      <b>+56 9 5158 7794</b>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:contacto@rvbionics.com">
                      <b>contacto@rvbionics.com</b>
                    </a>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div className="colCompanyContentImageContainer">
            <div className="embeddedMap">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.1374647456732!2d-70.59942602395702!3d-33.39357769460245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c8cab117d2cb%3A0x2cd237ce028dc14b!2sAv.%20Nueva%20Costanera%204229%2C%20Of%20203%2C%207630156%20Vitacura%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1sen!2sar!4v1683089495147!5m2!1sen!2sar"
                style={{ border: "0", width: "100%", height: "364px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyContent;
