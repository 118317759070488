import React from "react";

import Header from "../../components/Header/Header";
import HeroBlog from "../../components/Hero/HeroBlog";
import ResourcesArticleOne from "../../components/ResourcesContent/SeparatedPosts/FirstPost";
import Footer from "../../components/Footer/Footer";

const ResourcesArticleOneScreen = () => {
  return (
    <div>
      <Header />
      <HeroBlog />
      <ResourcesArticleOne />
      <Footer />
    </div>
  );
};

export default ResourcesArticleOneScreen;