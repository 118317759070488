import React from "react";

import "./HeroBlog.css";

const HeroBlog = () => {
  return (
    <div>
      <div className="blogHeroArea">
        <div className="blogHeroLargeExclusive">
          <div className="blogInnerHero">
            <div className="blogHeadingContainer">
              <h2 className="blogHeadingTitle">LATEST UPDATES</h2>
            </div>
            <h1 className="mainCompanyTitle">Blog & Resources</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBlog;
