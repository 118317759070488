import React from "react";

import "./HeroEnergyMgmt.css";

const dezemLogo = require("../../img/icons/deZemLogoWhite.png");

const HeroEnergyMgmt = () => {
  return (
    <div>
      <div className="energyMgmtHeroDesktopExclusive">
        <div className="energyMgmtHeroArea">
          <div className="energyMgmtHeroLargeExclusive">
            <div className="energyMgmtInnerArea">
              <div className="energyMgmtInnerHero">
                <h1>Energy Management</h1>
                <p>
                  The deZem energy management software is the key to tracking
                  your energy consumption, planning optimizations, evaluating
                  efficiency measures and quantifying savings.
                </p>
                <div className="energyMgmtHeroButtonContainer">
                  <div className="energyMgmtHeroButtonMain">
                    <a href="/#/contact">
                      <p>Get a demo</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="energyMgmtBrandingArea">
                <div className="energyMgmtBrandIcon">
                  <img src={dezemLogo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="energyMgmtHeroMobileExclusive">
        <div className="energyMgmtHeroArea">
          <div className="energyMgmtInnerArea">
            <div className="energyMgmtBrandingArea">
              <div className="energyMgmtBrandIcon">
                <img src={dezemLogo} alt="" />
              </div>
            </div>
            <div className="energyMgmtInnerHero">
              <h1>Energy Management</h1>
              <p>
                The deZem energy management software is the key to tracking your
                energy consumption, planning optimizations, evaluating
                efficiency measures and quantifying savings.
              </p>
              <div className="energyMgmtHeroButtonContainer">
                <div className="energyMgmtHeroButtonMain">
                  <a href="/#/contact">
                    <p>Get a demo</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroEnergyMgmt;
