import React from "react";
import Slider from "react-slick";

import SecuricsSlides from "./Slides";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./SecuritySoftware.css";
import "./SecuritySlideshow.css";

const SecuritySoftware = () => {
  return (
    <div>
      <div className="securicsArea">
        <div className="securicsLargeExclusive">
          <div className="securicsDescriptionContainerOverlay securicsDescriptionContainer">
            <div className="securicsHomeHeadingContainer">
              <h2>IN DEVELOPMENT</h2>
            </div>
            <a href="/#/industrial-cybersecurity">
              <h1>ICS/OT CyberSecurity Software</h1>
            </a>
            <p style={{ color: "#fff" }}>
              Our easy-to-deploy software safeguards your critical OT, IIoT & IT
              infrastructure with advanced security features that detect and
              prevent unauthorized access and malware attacks. With customizable
              options and comprehensive reporting, you can track security events
              and take proactive steps to prevent cyberattacks.
            </p>
            <a href="/#/industrial-cybersecurity">
              <h3 className="securicsRef">&gt;&gt; Find Out More</h3>
            </a>
          </div>
          <div className="securicsIndustriesLargeExclusive">
            <div className="homepageIndustriesSlider">
              <SecuricsSlides />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecuritySoftware;
