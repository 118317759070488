import React from "react";

import Header from "../components/Header/Header";
import HeroPrivacyPolicy from "../components/Hero/HeroPrivacyPolicy";
import PrivacyPolicyContent from "../components/PrivacyPolicyContent/PrivacyPolicyContent";
import Footer from "../components/Footer/Footer";

const PrivacyPolicyScreen = () => {
  return (
    <div>
      <Header />
      <HeroPrivacyPolicy />
      <PrivacyPolicyContent />
      <Footer />
    </div>
  );
};

export default PrivacyPolicyScreen;
