import React from "react";

import Header from "../components/Header/Header";
import HeroContact from "../components/Hero/HeroContact";
import Form from '../components/Form/Form';
import Footer from "../components/Footer/Footer";

const ContactScreen = () => {
  return (<div>
    <Header />
    <HeroContact />
    <Form id="main-contact-form" />
    <Footer />
  </div>);
};

export default ContactScreen;