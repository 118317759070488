/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

import AutoPlaySilentVideo from "../AutoplayVideo/AutoplayVideo";

import "./IndustrialConsultingContent.css";

import earthVideo from "../../media/techEarth.mp4";

const IndustrialConsultingContent = () => {
  return (
    <div className="industrialConsultingContentArea">
      <div className="desktopExclusive">
        <div className="industrialConsultingContentRow industrialConsultingContentLargeExclusive">
          <div className="colIndustrialConsultingContentImageContainer industrialConsultingBlockOneBg"></div>
          <div
            className="colIndustrialConsultingContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="industrialConsultingTitleDecorationLine" />
            <h3>The challenges of permanent connectivity</h3>
            <p>
              In the "permanently interconnected" environment of modern
              industries cyber threats became an extremely-relevant risk to
              critical infrastructure as a result of increasing reliance on
              networked systems.
              <br></br>
              <br></br>
              Consequently, it is now crucial for industrial environments to
              strengthen their cyber defense policies and stress on setting up a
              positive security culture.
            </p>
          </div>
        </div>
      </div>
      <div className="mobileExclusive">
        <div className="industrialConsultingContentRow">
          <div
            className="colIndustrialConsultingContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="industrialConsultingTitleDecorationLine" />
            <h3>The challenges of permanent connectivity</h3>
            <p>
              In the "permanently interconnected" environment of modern
              industries cyber threats became an extremely-relevant risk to
              critical infrastructure as a result of increasing reliance on
              networked systems.
              <br></br>
              <br></br>
              Consequently, it is now crucial for industrial environments to
              strengthen their cyber defense policies and stress on setting up a
              positive security culture.
            </p>
          </div>
          <div className="colIndustrialConsultingContentImageContainer">
            <div className="colIndustrialConsultingContentImageContainer industrialConsultingBlockOneBg">
            </div>
          </div>
        </div>
      </div>
      <div className="industrialConsultingContentRow industrialConsultingContentLargeExclusive">
        <div
          className="colIndustrialConsultingContentBlockContainer"
          style={{ paddingRight: "80px" }}
        >
          <div className="industrialConsultingTitleDecorationLine" />
          <h3>The evolving cybersecurity landscape</h3>
          <p>
            The industrial sector has witnessed a rapid convergence of IT and OT
            systems, allowing for greater efficiency and productivity. However,
            this convergence also exposes businesses to a broader range of
            cybersecurity risks. Industrial IT and OT assets, such as
            supervisory control and data acquisition (SCADA) systems,
            programmable logic controllers (PLCs), and human-machine interfaces
            (HMIs), are increasingly becoming prime targets for malicious actors
            seeking to disrupt operations, steal sensitive information, or cause
            physical damage.
          </p>
        </div>
        <div className="colIndustrialConsultingContentImageContainer industrialConsultingBlockTwoBg"></div>
      </div>
      <div className="desktopExclusive">
        <div className="industrialConsultingContentRow industrialConsultingContentLargeExclusive">
          <div className="colIndustrialConsultingContentImageContainer industrialConsultingBlockThreeBg"></div>
          <div
            className="colIndustrialConsultingContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="industrialConsultingTitleDecorationLine" />
            <h3>Proactive approach</h3>
            <p>
              Cybersecurity is an ongoing process that starts with the
              implementation of risk prevention and mitigation measures
              accompanied by a security awareness traning program.
              <br></br>
              <br></br>
              Our team of experienced consultants is at your disposal to help
              you assess your current security measures, identify
              vulnerabilities, and develop a comprehensive plan to safeguard
              against cyber threats.
            </p>
          </div>
        </div>
      </div>
      <div className="mobileExclusive">
        <div className="industrialConsultingContentRow">
          <div
            className="colIndustrialConsultingContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="industrialConsultingTitleDecorationLine" />
            <h3>Proactive approach</h3>
            <p>
              Cybersecurity is an ongoing process that starts with the
              implementation of risk prevention and mitigation measures
              accompanied by a security awareness traning program.
              <br></br>
              <br></br>
              Our team of experienced consultants is at your disposal to help
              you assess your current security measures, identify
              vulnerabilities, and develop a comprehensive plan to safeguard
              against cyber threats.
            </p>
          </div>
          <div className="colIndustrialConsultingContentImageContainer industrialConsultingBlockThreeBg"></div>
        </div>
      </div>
    </div>
  );
};

export default IndustrialConsultingContent;
