import React from "react";

import "./EnergyMgmtDescription.css";

const EnergyMgmtDescription = () => {
  return (
    <div className="descriptionArea">
      <div className="energyMgmtDescriptionLargeExclusive">
        <div className="innerDescription">
          <h1>Achieving impact with powerful software</h1>
          <div className="descriptionRow">
            <div className="leftCol">
              <p>
                Analyze the relationship between consumption, production and
                environmental conditions. <br></br>
                <br></br>Do this in real time for the entire company at a glance
                or in detail on individual machines.
              </p>
            </div>
            <div className="rightCol">
              <p>
                Alerting, peak load detection, characteristic values,
                preconfigured reports - the deZem energy management software
                includes all!
              </p>
              <p>
                Measured values from any source are collected on powerful
                servers and are plausibility checked, aggregated and stored in
                databases. You can access the software from your PC or mobile
                device to clearly display and analyze current and historical
                data.
              </p>
              <p>
                deZem's Energy Management Software is in constant upgrade
                development and has been growing for many years. We are proud to
                offer our customers a high-performance, precise and reliable
                solution that will delight your company management, auditors,
                experts and facility managers alike.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyMgmtDescription;
