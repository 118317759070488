import React, { Component } from "react";
import Slider from "react-slick";

export default class SecuricsSlides extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      pauseOnHover: true,
      pauseOnFocus: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    };
    return (
      <div style={{ margin: "auto" }}>
        <Slider {...settings}>
          <div
            className="homepageIndustrySlide industrySlideOverlay agroindustryBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Agroindustry</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay automotiveBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Automotive</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay chemicalBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Chemical</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay energyBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Energy</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay foodAndBevBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Food & Beverage</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay logisticsBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Logistics</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay manufacturingBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Manufacturing</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay miningBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Mining</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay nuclearPowerBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Nuclear Power</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay oilAndGasBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Oil & Gas</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay pharmaBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Pharmaceutical</h3>
          </div>
          <div
            className="homepageIndustrySlide industrySlideOverlay waterTreatmentBg"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h3>Water Treatment</h3>
          </div>
        </Slider>
      </div>
    );
  }
}
