import React from "react";

import "./PublicOrganizationsWindow.css";
import "../../vectors/companyVectors.css";

const PublicOrganizationsWindow = () => {
  return (
    <div>
      <div className="publicOrganizationsWindowArea">
        <div className="publicOrganizationsWindowInHomeLargeExclusive" style={{margin: "auto"}}>
          <div className="publicOrganizationsWindowContainer">
            <i className="rvb-logo-white-icon" style={{height: "100px", width: "auto"}}></i>
            <h1>
              Engineering the cybersecurity of the future
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicOrganizationsWindow;
