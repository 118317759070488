import React from "react";

import Header from "../components/Header/Header";
import HeroBlog from "../components/Hero/HeroBlog";
import ResourcesContent from "../components/ResourcesContent/ResourcesContent";
import Footer from "../components/Footer/Footer";

const ResourcesScreen = () => {
  return (
    <div>
      <Header />
      <HeroBlog />
      <ResourcesContent />
      <Footer />
    </div>
  );
};

export default ResourcesScreen;
