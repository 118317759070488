import React from "react";

import "./PublicOrganizationSecurityThreats.css";
import "../../vectors/cybersecVectors.css";
import "../../vectors/industrialVectors.css";

const antennaGraph = require("../../img/publicOrganizations/graphs/antenna.png");
// const fighterJetGraph = require("../../img/publicOrganizations/graphs/fighterJet.png");

const PublicOrganizationSecurityThreats = () => {
  return (
    <div className="publicOrganizationSecurityThreatsArea">
      <div className="publicOrganizationSecurityThreatsLargeExclusive">
        <div className="publicOrganizationSecurityThreatsInnerArea">
          <div className="desktopExclusive">
            <div className="publicOrganizationSecurityThreatsUpperContainer">
              <div className="publicOrganizationsTitleContainer">
                <h1>Helping governments maintain stability</h1>
              </div>
              <div className="publicOrganizationsGraphContainer">
                <img src={antennaGraph} alt={""} style={{ maxWidth: "90%" }} />
              </div>
            </div>
          </div>
          <div className="mobileExclusive">
            <div className="publicOrganizationSecurityThreatsUpperContainer">
              <div className="publicOrganizationsGraphContainer">
                <img src={antennaGraph} alt={""} style={{ maxWidth: "100%" }} />
              </div>
              <div className="publicOrganizationsTitleContainer">
                <h1>Helping governments maintain stability</h1>
              </div>
            </div>
          </div>
          <div className="publicOrganizationSecurityThreatsMainContainer">
            <div className="publicOrganizationSecurityThreatsRow">
              <div className="publicOrganizationSecurityThreatsCol">
                <i
                  className="rvb-confidentiality-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Defending individual privacy rights</h3>
                <p>
                  Public Organizations handle vast amounts of personal and
                  sensitive information about citizens, including healthcare
                  records, tax data, national indentification numbers, and more.
                  Solid cybersecurity measures protect this information from
                  unauthorized access, identity theft, and privacy breaches,
                  ensuring that citizens' personal data remains confidential and
                  secure.
                </p>
              </div>
              <div className="publicOrganizationSecurityThreatsCol">
                <i
                  className="rvb-gear-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Ensuring continuity of operations</h3>
                <p>
                  Government agencies provide essential services to citizens,
                  ranging from healthcare and education to transportation and
                  emergency response which cyberattacks could disrupt, leading
                  to service outages, delays, or even endangering lives.
                  Effective cybersecurity measures help ensure the continuity of
                  operations, minimizing the impact of potential cyber incidents
                  and allowing organizations to quickly recover and restore
                  services.
                </p>
              </div>
              <div className="publicOrganizationSecurityThreatsCol">
                <i
                  className="rvb-public-trust-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Maintaining public trust in government</h3>
                <p>
                  Government and public organizations have a responsibility to
                  maintain the trust and confidence of the public they serve.
                  Demonstrating a strong commitment to cybersecurity reassures
                  citizens that their data is protected and that their
                  interactions with these organizations are secure. It fosters
                  trust and credibility, reinforcing the relationship between
                  the government and the public.
                </p>
              </div>
              <div className="publicOrganizationSecurityThreatsCol">
                <i
                  className="rvb-financial-stability-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Economic stability and intellectual property protection</h3>
                <p>
                  Cyberattacks targeting public organizations can have
                  far-reaching economic consequences. Robust cybersecurity
                  measures protect intellectual property, trade secrets, and
                  proprietary information, safeguarding economic interests and
                  promoting innovation. It prevents theft of valuable
                  intellectual property, ensuring economic stability and
                  competitiveness on a national and global scale.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicOrganizationSecurityThreats;
