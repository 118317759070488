import React from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Form.css";

const formBackground = require("../../img/earthMapTransparent.png");

const Form = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast("Message sent successfully!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      className: "success",
      toastId: "notifyToast",
      theme: "colored",
    });
  };

  const onSubmit = async (data) => {
    const { name, company, email, phone, message, horns } = data;
    try {
      const templateParams = {
        name,
        company,
        email,
        phone,
        message,
        horns,
      };
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      );
      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="formArea">
      <div className="formLargeExclusive">
        <div
          className="formHeadingContainer"
          style={{
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <h2>CONTACT US</h2>
        </div>
        <h1>Get in touch with us</h1>
        <div className="blockContainers">
          <div className="formBlock">
            <div className="innerFormBlock">
              <form
                id="contact-form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <div className="labelContainer">
                  <label>Name *</label>
                  <div className="inputContainer">
                    <input
                      type="text"
                      name="name"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Please enter your name",
                        },
                        maxLength: {
                          value: 30,
                          message: "Please use 30 characters or less",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="labelContainer">
                  <label>Company</label>
                  <div className="inputContainer">
                    <input
                      type="text"
                      name="company"
                      {...register("company", {
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="labelContainer">
                  <label>Email *</label>
                  <div className="inputContainer">
                    <input
                      type="email"
                      name="email"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                    />
                  </div>
                </div>
                <div className="labelContainer">
                  <label>Telephone</label>
                  <div className="inputContainer">
                    <input
                      type="tel"
                      name="phone"
                      {...register("phone", {
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="labelContainer">
                  <label>Message *</label>
                  <div className="inputContainer">
                    <textarea
                      rows={7}
                      name="message"
                      {...register("message", {
                        required: true,
                      })}
                    />
                    {errors.message && (
                      <span className="errorMessage">
                        Please enter a message
                      </span>
                    )}
                  </div>
                </div>
                <div className="securityConfirmationContainer">
                  <input
                    type="checkbox"
                    id="horns"
                    name="horns"
                    {...register("horns", {
                      required: true,
                    })}
                  />
                  <label>
                    I consent to having this website store my submitted
                    information so that RVBionics can respond to my enquiry.
                  </label>
                </div>
                <button type="submit" className="submitButton">
                  <span>SEND EMAIL</span>
                </button>
              </form>
            </div>
            <ToastContainer />
          </div>
          <div className="contactBlock">
            <div className="contactBlockElement">
              <svg
                width="80"
                height="55"
                viewBox="0 0 80 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M70.2282 0H9.77183C4.38388 0 0 4.38388 0 9.77183V44.539C0 49.9269 4.38388 54.3108 9.77183 54.3108H70.2282C75.6161 54.3108 80 49.9269 80 44.539V9.77289C80.0011 4.38388 75.6172 0 70.2282 0ZM65.4533 6.37501L40 22.7737L14.5467 6.37501H65.4533ZM70.2282 47.9369H9.77183C7.89864 47.9369 6.37501 46.4122 6.37501 44.5401V9.95352L38.0333 30.311C38.0758 30.3376 38.1215 30.3557 38.1651 30.3801C38.2107 30.4056 38.2575 30.43 38.3042 30.4534C38.5497 30.5799 38.8026 30.6819 39.0607 30.7488C39.0873 30.7562 39.1139 30.7594 39.1404 30.7658C39.4241 30.8327 39.711 30.8742 39.9979 30.8742H40C40.0021 30.8742 40.0042 30.8742 40.0042 30.8742C40.2911 30.8742 40.578 30.8338 40.8617 30.7658C40.8882 30.7594 40.9148 30.7562 40.9414 30.7488C41.1996 30.6819 41.4514 30.5799 41.6979 30.4534C41.7446 30.43 41.7914 30.4056 41.8371 30.3801C41.8806 30.3557 41.9263 30.3376 41.9688 30.311L73.6271 9.95352V44.539C73.626 46.4122 72.1014 47.9369 70.2282 47.9369Z"
                  fill="#71EDEA"
                />
              </svg>
              <h3>Vicente Muñoz</h3>
              <h4>vicente@rvbionics.com</h4>
            </div>
            <div className="contactBlockElement">
              <svg
                width="80"
                height="55"
                viewBox="0 0 80 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M70.2282 0H9.77183C4.38388 0 0 4.38388 0 9.77183V44.539C0 49.9269 4.38388 54.3108 9.77183 54.3108H70.2282C75.6161 54.3108 80 49.9269 80 44.539V9.77289C80.0011 4.38388 75.6172 0 70.2282 0ZM65.4533 6.37501L40 22.7737L14.5467 6.37501H65.4533ZM70.2282 47.9369H9.77183C7.89864 47.9369 6.37501 46.4122 6.37501 44.5401V9.95352L38.0333 30.311C38.0758 30.3376 38.1215 30.3557 38.1651 30.3801C38.2107 30.4056 38.2575 30.43 38.3042 30.4534C38.5497 30.5799 38.8026 30.6819 39.0607 30.7488C39.0873 30.7562 39.1139 30.7594 39.1404 30.7658C39.4241 30.8327 39.711 30.8742 39.9979 30.8742H40C40.0021 30.8742 40.0042 30.8742 40.0042 30.8742C40.2911 30.8742 40.578 30.8338 40.8617 30.7658C40.8882 30.7594 40.9148 30.7562 40.9414 30.7488C41.1996 30.6819 41.4514 30.5799 41.6979 30.4534C41.7446 30.43 41.7914 30.4056 41.8371 30.3801C41.8806 30.3557 41.9263 30.3376 41.9688 30.311L73.6271 9.95352V44.539C73.626 46.4122 72.1014 47.9369 70.2282 47.9369Z"
                  fill="#71EDEA"
                />
              </svg>

              <h3>Ramiro Berríos</h3>
              <h4>ramiro@rvbionics.com</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;

// <div className="mapBgContainer">
//      <img src={formBackground} alt={""} />
// </div>
