import React from "react";

import "./Footer.css";

const logo = require("../../img/headerLogo.png");

const Footer = () => {
  return (
    <div className="footerArea">
      <div className="footerLargeExclusive">
        <div className="innerFooter">
          <div className="row">
            <div className="brandingCol">
              <a href="/">
                <img src={logo} alt={""} />
              </a>
            </div>
            <div className="contactCol">
              <h3>Contact Us</h3>
              <div className="locationContainer">
                <div className="locationElement">
                  <a href="https://goo.gl/maps/PgZaH2rvaybKYPM57?coh=178572&entry=tt">
                    <li>Av. Nueva Costanera 4229 of. 203.</li>
                  </a>
                  <a href="https://goo.gl/maps/PgZaH2rvaybKYPM57?coh=178572&entry=tt">
                    <li>Postal Code 7630156</li>
                  </a>
                  <a href="https://goo.gl/maps/PgZaH2rvaybKYPM57?coh=178572&entry=tt">
                    <li>Vitacura, Región Metropolitana.</li>
                  </a>
                  <a href="https://goo.gl/maps/PgZaH2rvaybKYPM57?coh=178572&entry=tt">
                    <li>Chile.</li>
                  </a>
                  <li>
                    <a href="tel:+14806300004">
                      <b>+1 (480) 630-0004</b>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:contacto@rvbionics.com">
                      <b>contacto@rvbionics.com</b>
                    </a>
                  </li>
                </div>
                <div className="locationElement">
                  <a href="https://goo.gl/maps/ZZtk9TfG9zN7zqcB7?coh=178572&entry=tt">
                    <li>Huergo 244, 7A. Palermo.</li>
                  </a>
                  <a href="https://goo.gl/maps/ZZtk9TfG9zN7zqcB7?coh=178572&entry=tt">
                    <li>Postal Code 1426</li>
                  </a>
                  <a href="https://goo.gl/maps/ZZtk9TfG9zN7zqcB7?coh=178572&entry=tt">
                    <li>Ciudad Autónoma de Buenos Aires.</li>
                  </a>
                  <a href="https://goo.gl/maps/ZZtk9TfG9zN7zqcB7?coh=178572&entry=tt">
                    <li>Argentina.</li>
                  </a>
                  <li>
                    <a href="tel:+14806300004">
                      <b>+1 (480) 630-0004</b>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:contacto@rvbionics.com">
                      <b>contacto@rvbionics.com</b>
                    </a>
                  </li>
                </div>
              </div>
            </div>
            <div className="linksCol">
              <h3>Links</h3>
              <div className="linksContainer">
                <div className="linksElement">
                  <li>
                    <a href="/#/industrial-cybersecurity">
                      Industrial CyberSecurity
                    </a>
                  </li>
                  {/*
                  <li>
                    <a href="/#/energy-management">Energy Management</a>
                  </li>
  */}
                  <li>
                    <a href="/#/industrial-security-services">
                      Services for industries
                    </a>
                  </li>
                  <li>
                    <a href="/#/commercial-security-services">
                      Services for business
                    </a>
                  </li>
                  <li>
                    <a href="/#/public-organizations">
                      Services for government
                    </a>
                  </li>
                </div>
                <div className="linksElement">
                  <li>
                    <a href="/#/resources">Blog and Resources</a>
                  </li>
                  <li>
                    <a href="/#/careers">Careers</a>
                  </li>
                  <li>
                    <a href="mailto:partners@rvbionics.com">Partnerships</a>
                  </li>
                  <li>
                    <a href="/#/company">About the company</a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrightArea">
          <h3 className="copyrightText">
            RVBIONICS (RV Bionics SpA) 2023. All Rights Reserved.
          </h3>
          <h5 className="privacyPolicyText">
            <a href="/#/privacy-policy">Privacy Policy</a>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Footer;
