import React from "react";

import "./HeroIndustrialConsulting.css";

const HeroIndustrialConsulting = () => {
  return (
    <div>
      <div className="industrialConsultingHeroArea">
        <div className="industrialConsultingHeroLargeExclusive">
          <div className="industrialConsultingInnerHero">
            <div className="industrialConsultingHeadingContainer">
              <h1 className="industrialConsultingHeadingTitle">SERVICES</h1>
            </div>
            <h1 className="mainCareersTitle">
              Cybersecurity<br></br> for Industries
            </h1>
            <p>
              At RVBionics, our mission is to empower industries to thrive into
              digital transformation while mitigating risks and maintaining the
              resilience of operational processes.
            </p>
            <div className="industrialConsultingHeroButtonContainer">
              <a href="/#/contact">
                <div className="industrialConsultingHeroButtonSecondary">
                  <p>Contact Us</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroIndustrialConsulting;
