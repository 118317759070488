import React, { useEffect } from "react";

import "./Modal.css";

const openPosition = {
  title: "Software Engineer (C/C++)",
  description: [
    "RVBionics is looking for a  Software Engineer to join our development team in Buenos Aires, Argentina. Our mission is to drive digital transformation in order to operate more efficiently, and securely by leveraging the latest technological advances targeting OT/ICS/IIoT systems and networks.",
    "We are looking to hire an experienced engineer to take an important role in the development of the Malware Analysis Engine for our upcoming Industrial Cybersecurity software as well as being an active advisor to the Full-Stack team pursuing top-notch performance and flawless connectivity.",
  ],
  responsibilities: [
    "Build vulnerability and threat detection systems.",
    "Analyze systems to understand the way they work, and break.",
    "Conduct multirole tasks to both build and defeat security tools.",
    "Ensure developed features connect appropriately across the platform.",
  ],
  requirements: [
    "At least four years of software development experience in a professional environment.",
    "At least one year of experience in networking / cybersecurity.",
    "Proficiency in several of the following languages: Python, C/C++, SQL, Rust, PowerShell, Lua, etc...",
    "Experience with at least one assembly language (x86, ARM, MIPS, etc…)",
    "Advanced understanding of good software architecture.",
    "Understanding of reverse and anti-reverse engineering techniques.",
    "Understanding of network protocols.",
    "Problem-solving and analytical skills.",
    "Excellent communication skills and enthusiasm for collaboration.",
  ],
};

const SoftwareEngineerModal = (props) => {
  if (!props.show) {
    document.body.style.overflow = "unset";
    return null;
  }

  if (props.show) {
    document.body.style.overflow = "hidden";
  }

  return (
    <div className="modal" onClick={props.onClose}>
      <div className="jobModalArea" onClick={(e) => e.stopPropagation()}>
        <div className="innerJobModal">
          <div className="closeModal">
            <button onClick={props.onClose} type="button">
              <svg
                width="20"
                height="20px"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="#fff" />
              </svg>
            </button>
          </div>
          <h2>{openPosition.title}</h2>
          <p>{openPosition.description[0]}</p>
          <p>{openPosition.description[1]}</p>
          <h3>Responsibilities:</h3>
          <ul>
            <li>{openPosition.responsibilities[0]}</li>
            <li>{openPosition.responsibilities[1]}</li>
            <li>{openPosition.responsibilities[2]}</li>
            <li>{openPosition.responsibilities[3]}</li>
          </ul>
          <h3>Requirements:</h3>
          <ul>
            <li>{openPosition.requirements[0]}</li>
            <li>{openPosition.requirements[1]}</li>
            <li>{openPosition.requirements[2]}</li>
            <li>{openPosition.requirements[3]}</li>
            <li>{openPosition.requirements[4]}</li>
            <li>{openPosition.requirements[5]}</li>
            <li>{openPosition.requirements[6]}</li>
            <li>{openPosition.requirements[7]}</li>
            <li>{openPosition.requirements[8]}</li>
          </ul>
          <h3>Interested in working at RVBionics?</h3>
          <p>
            Contact us via email introducing yourself at{" "}
            <a href="mailto:jobs@rvbionics.com">jobs@rvbionics.com</a> and
            attach a copy of your CV and any portfolio work you consider
            relevant.
            <br></br>
            Please include the type of role you’re after in the subject line. If
            you require any adjustments for interviews, please let us know and
            we will try and facilitate those needs as best we can.
          </p>
          <div className="applyButtonContainer">
            <div className="applyButton">
              <a href="mailto:jobs@rvbionics.com">
                <span>APPLY FOR THE POSITION</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareEngineerModal;
