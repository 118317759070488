/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

import "./CommercialConsultingContent.css";
import "../../vectors/cybersecVectors.css";

const CommercialConsultingContent = () => {
  return (
    <div className="commercialConsultingContentArea">
      <div className="desktopExclusive">
        <div className="commercialConsultingContentRow commercialConsultingContentLargeExclusive">
          <div className="colCommercialConsultingContentImageContainer commercialConsultingBlockTwoBg"></div>
          <div
            className="colCommercialConsultingContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="commercialConsultingTitleDecorationLine" />
            <h3>Cybersecurity as a service</h3>
            <p>
              We assist businesses build a strong and resilient cybersecurity
              frame that can effectively detect, prevent, and respond to cyber
              threats.
              <br></br>
              <br></br>
              Our team of professionals has the expertise to help your business
              to be well equipped to face the growing threat of cyber attacks
              and ensure the confidentiality, integrity, and availability of
              their digital assets.
            </p>
          </div>
        </div>
      </div>
      <div className="mobileExclusive">
        <div className="commercialConsultingContentRow">
          <div
            className="colCommercialConsultingContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="commercialConsultingTitleDecorationLine" />
            <h3>Cybersecurity as a service</h3>
            <p>
              We assist businesses build a strong and resilient cybersecurity
              frame that can effectively detect, prevent, and respond to cyber
              threats.
              <br></br>
              <br></br>
              Our team of professionals has the expertise to help your business
              to be well equipped to face the growing threat of cyber attacks
              and ensure the confidentiality, integrity, and availability of
              their digital assets.
            </p>
          </div>
          <div className="colCommercialConsultingContentImageContainer commercialConsultingBlockTwoBg"></div>
        </div>
      </div>
      <div className="commercialConsultingContentRow commercialConsultingContentLargeExclusive">
        <div
          className="colCommercialConsultingContentBlockContainer"
          style={{ paddingRight: "80px" }}
        >
          <div className="commercialConsultingTitleDecorationLine" />
          <h3>Always in movement</h3>
          <p>
            Cybersecurity is an ongoing process, and we are looking forward to
            guide you in its entirety.
            <br></br>
            <br></br>
            Initially setting up a security-positive workspace to enable more
            in-depth services and information gathering followed by assistance
            promoting a positive security culture backed up by proper security
            awareness training.
          </p>
        </div>
        <div className="colCommercialConsultingContentImageContainer commercialConsultingBlockOneBg"></div>
      </div>
      <div className="desktopExclusive">
        <div className="commercialConsultingContentRow commercialConsultingContentLargeExclusive">
          <div className="colCommercialConsultingContentImageContainer commercialConsultingBlockThreeBg"></div>
          <div
            className="colCommercialConsultingContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="commercialConsultingTitleDecorationContainer">
              <div className="commercialConsultingTitleDecorationLine" />
            </div>
            <h3>Prevent financial losses</h3>
            <p>
              Cyberattacks can lead to significant financial losses, including
              the costs of incident response, recovery, legal liabilities, and
              potential regulatory fines.
              <br></br>
              By actively combatting cybersecurity threats, businesses can
              reduce the risk of financial losses associated with cyber
              incidents and allocate resources more efficiently.
            </p>
          </div>
        </div>
      </div>
      <div className="mobileExclusive">
        <div className="commercialConsultingContentRow">
          <div
            className="colCommercialConsultingContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="commercialConsultingTitleDecorationContainer">
              <div className="commercialConsultingTitleDecorationLine" />
            </div>
            <h3>Prevent financial losses</h3>
            <p>
              Cyberattacks can lead to significant financial losses, including
              the costs of incident response, recovery, legal liabilities, and
              potential regulatory fines.
              <br></br>
              By actively combatting cybersecurity threats, businesses can
              reduce the risk of financial losses associated with cyber
              incidents and allocate resources more efficiently.
            </p>
          </div>
          <div className="colCommercialConsultingContentImageContainer commercialConsultingBlockThreeBg"></div>
        </div>
      </div>
    </div>
  );
};

export default CommercialConsultingContent;
