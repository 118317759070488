import React from "react";

import "./IndustrialServices.css";
import "../../vectors/industrialVectors.css";
import "../../vectors/cybersecVectors.css";
import "../../vectors/industrySecVectors.css";

// const antennaGraph = require("../../img/publicOrganizations/graphs/antenna.png");
const nuclearPowerGraph = require("../../img/industriesConsulting/graph/nuclearPower.png");

const IndustrialServices = () => {
  return (
    <div className="industrialConsultingServicesArea">
      <div className="industrialConsultingServicesGridListLargeExclusive">
        <div className="industrialConsultingServicesInnerGridListArea">
          <div className="desktopExclusive">
            <div className="industrialConsultingServicesUpperContainer">
              <div className="industrialConsultingTitleContainer">
                <h1>
                  The path to <br></br>cyber resilience
                </h1>
              </div>
              <div className="industrialConsultingsGraphContainer">
                <img src={nuclearPowerGraph} alt={""} />
              </div>
            </div>
          </div>
          <div className="mobileExclusive">
            <div className="industrialConsultingServicesUpperContainer">
              <div className="industrialConsultingsGraphContainer">
                <img src={nuclearPowerGraph} alt={""} />
              </div>
              <div className="industrialConsultingTitleContainer">
                <h1>
                  The path to <br></br>cyber resilience
                </h1>
              </div>
            </div>
          </div>
          <div className="industrialConsultingServicesListContainer">
            <div className="industrialConsultingServicesRow">
              <div className="industrialServicesCol">
                <i
                  className="rvb-industry-risk-assessment-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="industrialServicesColTitleContainer">
                  <h3>Risk assessment and management</h3>
                </div>
                <p className="industrialConsultingServicesParagraph">
                  Conducting comprehensive risk assessments is crucial to
                  identify vulnerabilities, potential risks, and the impact of
                  cyber attacks on industrial systems.
                  <br></br>
                  This process helps prioritize security measures based on the
                  likelihood and potential consequences of an attack.
                </p>
              </div>
              <div className="industrialServicesCol">
                <i
                  className="rvb-industry-network-security-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="industrialServicesColTitleContainer">
                  <h3>
                    Network <br></br>security
                  </h3>
                </div>
                <p className="industrialConsultingServicesParagraph">
                  Network security focuses on protecting an industry's network
                  infrastructure from unauthorized access, intrusions, and data
                  breaches by setting up demilitarized zones (DMZ), firewalls,
                  intrusion detection systems (IDS), virtual private networks
                  (VPNs), and conducting regular network monitoring.
                </p>
              </div>
              <div className="industrialServicesCol">
                <i
                  className="rvb-endpoint-security-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="industrialServicesColTitleContainer">
                  <h3>
                    Endpoint <br></br>security
                  </h3>
                </div>
                <p className="industrialConsultingServicesParagraph">
                  OT and IoT devices, including industrial sensors, actuators,
                  and controllers, require specific security measures. We assist
                  industries implement endpoint protection solutions tailored
                  for these devices. This includes deploying security agents,
                  applying device hardening techniques, and ensuring firmware
                  integrity.
                </p>
              </div>
            </div>
            <div className="industrialConsultingServicesRow">
              <div className="industrialServicesCol">
                <i
                  className="rvb-industry-incident-response-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="industrialServicesColTitleContainer">
                  <h3>Incident response and recovery</h3>
                </div>
                <p className="industrialConsultingServicesParagraph">
                  In the event of a cybersecurity incident or breach we assist
                  industries in responding effectively.
                  <br></br>
                  By developing incident response plans specific to industrial
                  systems, conduct forensic investigations, contain the
                  incident, and restore affected systems and operations to
                  normalcy.
                </p>
              </div>
              <div className="industrialServicesCol">
                <i
                  className="rvb-industry-supply-chain-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="industrialServicesColTitleContainer">
                  <h3>Supply chain security</h3>
                </div>
                <p className="industrialConsultingServicesParagraph">
                  Industries rely on various vendors and suppliers for OT/IoT
                  components and systems.
                  <br></br>
                  Ensuring the security of the supply chain is critical to
                  prevent the introduction of compromised hardware or software
                  components that could pose a risk to the overall cybersecurity
                  posture.
                </p>
              </div>
              <div className="industrialServicesCol">
                <i
                  className="rvb-data-governance-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="industrialServicesColTitleContainer">
                  <h3>
                    Data <br></br>governance
                  </h3>
                </div>
                <p className="industrialConsultingServicesParagraph">
                  We assist industries adopt an overall strategy and enforce
                  guidelines for handling data throughout its lifecycle,
                  including collection, storage, usage, sharing, and disposal,
                  with a specific focus on maintaining confidentiality,
                  integrity, and availability.
                </p>
              </div>
            </div>
            <div className="industrialConsultingServicesRow">
              <div className="industrialServicesCol">
                <i
                  className="rvb-secure-remote-access-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="industrialServicesColTitleContainer">
                  <h3>Secure remote access</h3>
                </div>
                <p className="industrialConsultingServicesParagraph">
                  Remote access to OT and IoT systems is often required for
                  maintenance and troubleshooting purposes.
                  <br></br>We provide you with secure remote access solutions
                  such as virtual private networks, multi-factor auth. and
                  secure remote desktop protocols while ensuring proper access
                  controls and monitoring.
                </p>
              </div>
              <div className="industrialServicesCol">
                <i
                  className="rvb-security-awareness-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="industrialServicesColTitleContainer">
                  <h3>Security awareness program</h3>
                </div>
                <p className="industrialConsultingServicesParagraph">
                  Human factors play a significant role in cybersecurity
                  therefore it has become crucial to train industry personnel
                  and employees on best practices for cybersecurity.
                  <br></br>
                  We assist industrial companies perform awareness campaigns and
                  training programs to build an internal security-conscious
                  culture.
                </p>
              </div>
              <div className="industrialServicesCol">
                <i
                  className="rvb-compliance-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="industrialServicesColTitleContainer">
                  <h3>Compliance and regulation control</h3>
                </div>
                <p className="industrialConsultingServicesParagraph">
                  Industries often operate under specific regulatory frameworks
                  and compliance requirements. We offer assistance in navigating
                  these regulations, ensuring compliance with industry
                  standards, and preparing for audits and assessments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustrialServices;
