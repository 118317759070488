import React from "react";

import "./IndustrialConsultingWindow.css";

const IndustrialConsultingWindow = () => {
  return (
    <div>
      <div className="industrialConsultingWindowArea">
        <div
          className="industrialConsultingWindowInHomeLargeExclusive"
          style={{ margin: "auto" }}
        >
          <div className="industrialConsultingWindowContainer">
            <i
              className="rvb-logo-white-icon"
              style={{ height: "100px", width: "auto" }}
            ></i>
            <h1>Engineering the cybersecurity of the future</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustrialConsultingWindow;