import React from "react";

import "./PrivacyPolicyContent.css";

const PrivacyPolicyContent = () => {
  return (
    <div className="privacyPolicyContentArea">
      <div className="privacyPolicyContentLargeExclusive">
        <div className="privacyPolicyInnerContent">
          <div
            className="privacyPolicyArticleText privacyPolicyParagraph"
            style={{ paddingTop: "0px" }}
          >
            RVBIONICS (“we", "us", or "our") operates{" "}
            <a href="/">https://www.rvbionics.com/</a> (the “site”) and are
            committed to protect the privacy and security of your personal
            information. This Privacy Policy outlines how we collect, use,
            disclose, and store personal information regarding the users of the
            site.
            <br></br>
            <br></br>
            By using our site, you consent to the practices described in this
            Privacy Policy.
            <br></br>
            <br></br>
            <ol>
              <li>
                <b>Information We Collect:</b>
              </li>
              <ol>
                <li>
                  <b>Personal Information:</b> We may collect personal
                  information, such as your name, email address, phone number,
                  and job title when you voluntarily provide it to us or when we
                  obtain it from other sources with your consent. We only
                  collect personal information that is necessary for the
                  provision of our products, solutions and services.
                </li>
                <li>
                  <b>Usage and Log Data:</b> When you use our site we may
                  automatically collect certain information about your usage
                  patterns and interactions, including your IP address, browser
                  type, operating system, device information, and pages visited.
                  This information is collected to improve our site and ensure
                  its security and functionality.
                </li>
              </ol>
              <li>
                <b>Use of Information:</b>
              </li>
              <ol>
                <li>
                  <b>Provision of Products and Services:</b> We may use the
                  information we collect to provide you with our products,
                  solutions and services.
                </li>
                <li>
                  <b>Communication and Marketing:</b> We may use your contact
                  information to communicate with you regarding our products,
                  solutions, services, resources and other relevant information.
                  You have the option to opt out of receiving marketing
                  communications from us.
                </li>
                <li>
                  <b>Analytics and improvements:</b> We may use the collected
                  information for analytical purposes, such as monitoring
                  trends, enhancing our products, solutions, services, site and
                  conducting research to improve these.
                </li>
                <li>
                  <b>Legal Compliance and Protection:</b> We may use and
                  disclose your information to comply with applicable laws,
                  regulations, legal processes, or enforceable governmental
                  requests. We may also use and disclose your information to
                  protect our rights, privacy, safety, or property, as well as
                  those of our users, partners, or the public, to detect and
                  prevent fraud or other security issues.
                </li>
              </ol>
              <li>
                <b>Sharing of Information:</b> We may share your personal
                information in the following circumstances:
              </li>
              <ol>
                <li>
                  <b>Service Providers:</b> We may engage trusted third-party
                  service providers to assist us in delivering our products,
                  solutions and services. These providers are contractually
                  obligated to maintain the confidentiality and security of your
                  personal information.
                </li>
                <li>
                  <b>Business Transfers:</b> If we undergo a merger,
                  acquisition, or sale of all or a portion of our assets, your
                  personal information may be transferred as part of the
                  transaction. We will notify you via email or prominent notice
                  on our website of any such change in ownership or control of
                  your personal information.
                </li>
                <li>
                  We may disclose your personal information if required to do so
                  by law or in response to valid legal requests, such as
                  subpoenas, court orders, or government regulations.
                </li>
                <li>
                  <b>Data Security Legal Requirements:</b> We implement
                  reasonable security measures to protect your personal
                  information from unauthorized access, use, or disclosure.
                  However, please note that no method of transmission over the
                  Internet or electronic storage is completely secure, and we
                  cannot guarantee absolute security.
                </li>
              </ol>
              <li>
                <b>Data Retention:</b>
              </li>
              <ol>
                <li>
                  We retain your personal information for as long as necessary
                  to fulfill the purposes outlined in this Privacy Policy unless
                  a longer retention period is required or permitted by law.
                  When the information is no longer required, we will securely
                  dispose of it in accordance with our data retention and
                  destruction policies.
                </li>
              </ol>
              <li>
                <b>Your Rights:</b>
              </li>
              <ol>
                <li>
                  Subject to applicable laws, you may have certain rights
                  regarding your personal information, including the right to
                  access, correct, or delete your information. To exercise these
                  rights or if you have any questions or concerns about your
                  personal information and this privacy policy, please contact
                  us.
                </li>
              </ol>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
