import React from "react";

import "./HeroSecuritySoftware.css";

const HeroSecuritySoftware = () => {
  return (
    <div>
      <div className="securitySoftwareHeroArea">
        <div className="securicsHeroLargeExclusive">
          <div className="securicsInnerHero">
            <h1>Industrial Cybersecurity</h1>
            <p>
              Upcoming software specifically designed to safeguard your
              industrial control systems, OT environments and networks from
              cyber attacks. <br></br><br></br>
              With our advanced security features, you can detect
              and prevent unauthorized access, safeguard against malware, and
              protect your critical infrastructure.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSecuritySoftware;
