import React from "react";

import "./securicsGridList.css";

const SecuricsGridList = () => {
  return (
    <div className="securicsGridListArea">
      <div className="securicsGridListLargeExclusive">
        <div className="securicsInnerGridListArea">
          <div className="securicsUpperContainer">
            <div className="securicsGridListHeadingContainer">
              <h2>PROJECT GOALS</h2>
            </div>
            <h1>Redefining Cybersecurity for Industries</h1>
            <h4>
              We are currently working in a fully integrated solution that
              offers various products and services to protect the operational
              technology layers of industrial companies. This platform ensures
              the protection of critical components such as DCS systems, SCADA
              architectures, HMI systems, controllers such as PLCs, IEDs,
              robotic automation, OT network equipment, gateways, and
              engineering and operations workstations. Consisting of a unique
              approach to OT, Industrial Control Systems (ICS) and Industrial
              Internet of Things (IIoT) emphasizes the integration of IT and OT
              security operations, ensuring the seamless integration with the
              rest of the specialized solutions provided by us. The project’s
              ultimate goal is that industrial organizations can safeguard their
              technology without impacting system availability or consistency.
            </h4>
          </div>
          <div className="securicsListContainer">
            <div className="securicsRow">
              <div className="securicsCol">
                <div className="securicsColTitleContainer">
                  <h3>IT/OT Convergence</h3>
                  <i className="securicsRvbIcon"></i>
                </div>
                <p className="securicsParagraph">
                  Full integration of Information Technology (IT) and Operation
                  Technology (OT) plays a key role in order to streamline
                  industrial operations in the modern world.
                </p>
              </div>
              <div className="securicsCol">
                <div className="securicsColTitleContainer">
                  <h3>IoT Security</h3>
                  <i className="securicsRvbIcon"></i>
                </div>
                <p className="securicsParagraph">
                  Smart Device Interconnection presents a substantial challenge
                  in terms of cybersecurity risks mainly as a result of
                  unsecured networks and assets.
                </p>
              </div>
            </div>
            <div className="securicsRow">
              <div className="securicsCol">
                <div className="securicsColTitleContainer">
                  <h3>Collective Defense</h3>
                  <i className="securicsRvbIcon"></i>
                </div>
                <p className="securicsParagraph">
                  Real-time threat intelligence and warning system, capable of
                  adjustable incident responses to be triggered within your
                  network.
                </p>
              </div>
              <div className="securicsCol">
                <div className="securicsColTitleContainer">
                  <h3>Compliance Manager</h3>
                  <i className="securicsRvbIcon"></i>
                </div>
                <p className="securicsParagraph">
                  Choose or create your framework and let our software follow
                  your set of rules to detect, report and evaluate compliance.
                </p>
              </div>
            </div>
            <div className="securicsRow">
              <div className="securicsCol">
                <div className="securicsColTitleContainer">
                  <h3>Improved Visibility</h3>
                  <i className="securicsRvbIcon"></i>
                </div>
                <p className="securicsParagraph">
                  Map your network within our platform, and adjust visibility by
                  applying demilitarization and isolation methods in addition of
                  custom requests for real-time insights on an asset.
                </p>
              </div>
              <div className="securicsCol">
                <div className="securicsColTitleContainer">
                  <h3>Real-Time Scanning</h3>
                  <i className="securicsRvbIcon"></i>
                </div>
                <p className="securicsParagraph">
                  Software Engine capable of performing real-time scanning on
                  your network to detect any incoming threat or open
                  vulnerability within your organization.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecuricsGridList;
