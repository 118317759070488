import React from "react";

import "./Showcase.css";

const Showcase = () => {
  return (
    <div>
      <div className="showcaseArea">
        <div className="showcaseLargeExclusive">
          <div className="showcaseProductContainer">
            {/* 
            <div className="showcaseProductLeftContainer">
              <div className="showcaseProductLeftWrap">
                <h3 style={{ textAlign: "left" }}>
                  Energy Management Software
                </h3>
                <p style={{ textAlign: "left" }}>
                  Optimize your industrial energy usage and reduce costs with
                  Dezem's Energy Management Software. Real-time monitoring,
                  customizable features, and comprehensive reporting help you
                  make informed decisions and improve sustainability.
                </p>
                <a href="/#/energy-management">
                  <h4>Learn More -&gt;</h4>
                </a>
              </div>
            </div>
            */}
            <div className="showcaseProductLeftContainer">
              <div className="showcaseProductLeftWrap">
                <h3 style={{ textAlign: "left" }}>Cybersecurity services</h3>
                <p style={{ textAlign: "left" }}>
                  Our cybersecurity consulting services specialize in
                  safeguarding public organizations, industries, and businesses
                  from digital threats. With an expert team of professionals, we
                  offer comprehensive assessments, risk management strategies,
                  and tailored solutions to protect sensitive data and critical
                  systems.
                </p>
                <a href="/#/industrial-security-services">
                  <h4>Learn More -&gt;</h4>
                </a>
              </div>
            </div>
            <div className="showcaseProductRightContainer">
              <div className="showcaseProductRightWrap">
                <h3 style={{ textAlign: "left" }}>Industrial Cybersecurity</h3>
                <p style={{ textAlign: "left" }}>
                  Upcoming software specifically designed to safeguard your
                  industrial control systems, OT environments and networks from
                  cyber attacks. With our advanced security features, you can
                  detect and prevent unauthorized access, safeguard against
                  malware, and protect your critical infrastructure.
                </p>
                <a href="/#/industrial-cybersecurity">
                  <h4>Learn More -&gt;</h4>
                </a>
              </div>
            </div>
          </div>
          <div className="showcaseDesktopExclusive">
            <div className="wideSlimArea">
              <div className="wideSlimContainer">
                <a href="/#/careers">
                  <span>Work With Us</span>
                </a>
                {/*<a href="/#/careers"><h4>Learn More -&gt;</h4></a>*/}
              </div>
            </div>
          </div>
        </div>
        <div className="showcaseMobileExclusive">
          <div className="wideSlimArea">
            <div className="wideSlimContainer">
              <a href="/#/careers">
                <span>Work With Us</span>
              </a>
            </div>
            <a href="/#/careers">
              <h4>Learn More -&gt;</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcase;
