import React from "react";

import Header from "../components/Header/Header";
import HeroCompany from "../components/Hero/HeroCompany";
import CompanyContent from "../components/CompanyContent/CompanyContent";
import Form from '../components/Form/Form';
import Footer from "../components/Footer/Footer";

const CompanyScreen = () => {
  return (<div>
    <Header />
    <HeroCompany />
    <CompanyContent />
    <Form id="main-contact-form" />
    <Footer />
  </div>);
};

export default CompanyScreen;