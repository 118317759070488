import React from 'react';

import Header from '../components/Header/Header';
import HeroSecuritySoftware from '../components/Hero/HeroSecuritySoftware';
import SecuricsGridList from '../components/GridList/securicsGridList';
import Industries from '../components/Industries/Industries';
import Footer from '../components/Footer/Footer';
import Form from '../components/Form/Form';

const SecuritySoftwareScreen = () => {
    return (
        <div>
            <Header />
            <HeroSecuritySoftware />
            <SecuricsGridList />
            <Industries />
            <Form />
            <Footer />
        </div>
    )
}

export default SecuritySoftwareScreen;