import React from "react";

import "./HeroCommercialConsulting.css";

const HeroCommercialConsulting = () => {
  return (
    <div>
      <div className="commercialConsultingHeroArea">
        <div className="commercialConsultingHeroLargeExclusive">
          <div className="commercialConsultingInnerHero">
            <div className="commercialConsultingHeadingContainer">
              <h1 className="commercialConsultingHeadingTitle">SERVICES</h1>
            </div>
            <h1 className="mainCareersTitle">
              Cybersecurity<br></br> for Business
            </h1>
            <p>
              At RVBionics we are committed to help businesses build a strong
              defense against cyber threats, enabling them to focus on their
              core operations while ensuring the safety of their digital assets
              and the continuity of their business.
            </p>
            <div className="commercialConsultingHeroButtonContainer">
              <a href="/#/contact">
                <div className="commercialConsultingHeroButtonSecondary">
                  <p>Contact Us</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCommercialConsulting;
