import React from "react";

import "./HeroCareers.css";

const HeroCareers = () => {
  return (
    <div>
      <div className="careersHeroArea">
        <div className="careersHeroLargeExclusive">
          <div className="careersInnerHero">
            <div className="careersHeadingContainer">
              <h1 className="careersHeadingTitle">CAREERS</h1>
            </div>
            <h1 className="mainCareersTitle">
              Help us build a more efficient world
            </h1>
            <p>
              Join our mission to develop and provide the tools needed to
              navigate this new digital era, and work with our team of
              innovation-commited professionals to drive change in order to
              ensure a more efficient and secure future.
            </p>
            <a href="/#/company/">
              <div className="careersHeroButtonContainer">
                <div className="careersHeroButtonSecondary">
                  <p>About RVBionics</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCareers;
