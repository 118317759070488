import React from "react";

import "./HeroPublicOrganizations.css";

const HeroPublicOrganizations = () => {
  return (
    <div>
      <div className="publicOrganizationsHeroArea">
        <div className="publicOrganizationsHeroLargeExclusive">
          <div className="publicOrganizationsInnerHero">
            <div className="publicOrganizationsHeadingContainer">
              <h1 className="publicOrganizationsHeadingTitle">SERVICES</h1>
            </div>
            <h1 className="mainCareersTitle">
              Cybersecurity for Government & Defense
            </h1>
            <p>
              At RVBionics we strive to empower government, military and a
              variety of public organizations to securely face cyber threats
              across their critical infrastructure to safeguard internal
              stability and national safety.
            </p>
            <div className="publicOrganizationsHeroButtonContainer">
              <a href="/#/contact">
                <div className="publicOrganizationsHeroButtonSecondary">
                  <p>Contact Us</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroPublicOrganizations;
