import React from "react";

import "./CommercialSecurityThreats.css";
import "../../vectors/cybersecVectors.css";
import "../../vectors/industrialVectors.css";

const CommercialSecurityThreats = () => {
  return (
    <div className="commercialSecurityThreatsArea">
      <div className="commercialSecurityThreatsLargeExclusive">
        <div className="commercialSecurityThreatsInnerArea">
          <div className="commercialSecurityThreatsUpperContainer">
            <div className="commercialTitleContainer">
              <h1>What cyber risks are businesses exposed to?</h1>
            </div>
          </div>
          <div className="commercialSecurityThreatsMainContainer">
            <div className="commercialSecurityThreatsRow">
              <div className="commercialSecurityThreatsCol">
                <i
                  className="rvb-backdoor-alert-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Data breaches</h3>
                <p>
                  A data breach occurs when unauthorized individuals gain access
                  to sensitive or confidential data. This can lead to the
                  exposure of customer information, intellectual property, or
                  trade secrets. Data breaches can occur due to weak security
                  measures, insider threats, or persistent attacks.
                </p>
              </div>
              <div className="commercialSecurityThreatsCol">
                <i
                  className="rvb-briefcase-compromised-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Malware and ransomware attacks</h3>
                <p>
                  Malware encompasses various types of malicious software that
                  infiltrate computer systems or networks to gain unauthorized
                  access or cause harm. Among these, ransomware attacks are a
                  specific type where the malware encrypts data and demands a
                  ransom for its release. These attacks pose significant risks
                  to businesses, leading to disruptions, data loss, financial
                  harm, and reputational damage.
                </p>
              </div>
              <div className="commercialSecurityThreatsCol">
                <i
                  className="rvb-mail-phishing-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Phishing and social engineering</h3>
                <p>
                  Both are deceptive techniques used by cybercriminals to trick
                  employees into revealing sensitive information or compromising
                  system security posing significant cybersecurity risks to
                  businesses, leading to data breaches, financial losses, and
                  reputational harm... <br></br> Phishing involves fraudulent
                  emails, messages, or calls impersonating reputable sources to
                  gain sensitive information, while social engineering
                  manipulates human vulnerabilities
                </p>
              </div>
              <div className="commercialSecurityThreatsCol">
                <i
                  className="rvb-exploit-alert-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <h3>Unsecure supply chain</h3>
                <p>
                  An unsecure supply chain refers to a situation where
                  vulnerabilities or weaknesses exist within the network of
                  suppliers, vendors, or partners that a business relies on.
                  These vulnerabilities can be exploited by malicious actors to
                  gain unauthorized access, compromise data integrity, or
                  disrupt operations. It poses significant risks to businesses
                  as it can lead to data breaches, compromise the
                  confidentiality of sensitive information, and result in
                  financial losses or damage to the business's reputation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommercialSecurityThreats;
