import React from "react";

import Header from "../../components/Header/Header";
import HeroBlog from "../../components/Hero/HeroBlog";
import ResourcesArticleTwo from "../../components/ResourcesContent/SeparatedPosts/SecondPost";
import Footer from "../../components/Footer/Footer";

const ResourcesArticleTwoScreen = () => {
  return (
    <div>
      <Header />
      <HeroBlog />
      <ResourcesArticleTwo />
      <Footer />
    </div>
  );
};

export default ResourcesArticleTwoScreen;