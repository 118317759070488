import React from 'react';

import Header from '../components/Header/Header';
import HeroIndustrialConsulting from '../components/Hero/HeroIndustrialConsulting';
import IndustrialConsultingContent from "../components/ConsultingContent/IndustrialConsultingContent";
import IndustrialServices from "../components/ServicesConsulting/IndustrialServices";
import IndustrialSecurityThreats from '../components/ThreatsContent/IndustrialSecurityThreats';
import IndustrialConsultingWindow from '../components/Windows/IndustrialConsultingWindow';
import Footer from '../components/Footer/Footer';

const IndustrialConsultingScreen = () => {
    return (
        <div>
            <Header />
            <HeroIndustrialConsulting />
            <IndustrialConsultingContent />
            <IndustrialSecurityThreats />
            <IndustrialServices />
            <IndustrialConsultingWindow />
            <Footer />
        </div>
    )
}

export default IndustrialConsultingScreen;