import React from "react";

import "./Blog.css";

//const placeHolderImage = require("../../img/articlePlaceholder.png");
const mainArticleImage = require("../../img/blog/Q3/001.png");
const secondaryArticleImage = require("../../img/blog/Q2/003_compact.png");
const thirdArticleImage = require("../../img/blog/Q2/002_compact.png");

const Blog = () => {
  return (
    <div className="blogArea">
      <div className="blogHomeLargeExclusive">
        <div className="upperContainer">
          <a href="/#/resources">
            <h1 className="mainTitle">Blog & Resources</h1>
          </a>
          <div className="upperButton">
            <a href="/#/resources">
              <span>READ MORE CONTENT</span>
            </a>
          </div>
        </div>
        <div className="articlesContainer">
          <a href="/#/resources/the-human-factor">
            <div className="articleMainElement">
              <img src={mainArticleImage} alt={""} />
              <div className="mainLabelContainer">
                <div className="label">
                  <span>08/09/2023</span>
                </div>
                <div className="label">
                  <span>#cybersecurity</span>
                </div>
              </div>
              <div className="innerArticle">
                <h1>The Human Factor in Cybersecurity</h1>
                <p>
                  In the modern days, industrial plants have more interconnected
                  processes running than ever before, and this, has turned them
                  increasingly vulnerable to multiple types of cyber threats,
                  and all of us, mankind as a whole, are paying a lot of
                  attention to the implementation of advanced technologies to
                  address this issue, which is key to build a security-positive
                  environment.
                </p>
                <h3 className="readMore">&gt;&gt; Find Out More</h3>
              </div>
            </div>
          </a>
          {/* SECOND ARTICLE */}
          <a href="/#/resources/mitre-ics">
            <div className="articleElement">
              <img src={secondaryArticleImage} alt={""} />
              <div className="articleLabelContainer">
                <div className="label">
                  <span>05/25/2023</span>
                </div>
              </div>
              <div className="innerArticle">
                <h1 style={{ fontSize: "29px", lineHeight: "80%" }}>
                  ICS Cybersecurity with MITRE ATT&CK
                </h1>
                <p>
                  MITRE ATT&CK (Adversarial Tactics, Techniques and Common
                  Knowledge) is a technical knowledge base intended to
                  categorize and understand cyber threats.
                </p>
                <h3 className="readMore">&gt;&gt; Find Out More</h3>
              </div>
            </div>
          </a>
          {/* THIRD ARTICLE */}
          <a href="/#/resources/rising-relevance-cybersec">
            <div className="articleElement">
              <img src={thirdArticleImage} alt={""} />
              <div className="articleLabelContainer">
                <div className="label">
                  <span>05/25/2023</span>
                </div>
              </div>
              <div className="innerArticle">
                <h1 style={{ fontSize: "29px", lineHeight: "80%" }}>
                  Rising Relevance of Cybersecurity
                </h1>
                <p>
                  In an era of ongoing technological advancements
                  hyperconnection, the relevance of cybersecurity has reached
                  new heights…
                </p>
                <h3 className="readMore">&gt;&gt; Find Out More</h3>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Blog;
