import React from "react";

import "./CommercialServices.css";
import "../../vectors/industrialVectors.css";
import "../../vectors/cybersecVectors.css";
import "../../vectors/industrySecVectors.css";

const CommercialServices = () => {
  return (
    <div className="commercialConsultingServicesArea">
      <div className="commercialConsultingServicesGridListLargeExclusive">
        <div className="commercialConsultingServicesInnerGridListArea">
          <div className="commercialConsultingServicesUpperContainer">
            <div className="commercialConsultingTitleContainer">
              <h1>The path to cyber resilience</h1>
            </div>
          </div>
          <div className="commercialConsultingServicesListContainer">
            <div className="commercialConsultingServicesRow">
              <div className="commercialServicesCol">
                <i
                  className="rvb-risk-assessment-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="commercialConsultingServicesColTitleContainer">
                  <h3>Risk assessment and management</h3>
                </div>
                <p className="commercialConsultingServicesParagraph">
                  Conducting comprehensive risk assessments is crucial to
                  identify vulnerabilities, potential risks, and the impact of
                  cyber attacks on your business assets.
                  <br></br>
                  This process helps prioritize security measures based on the
                  likelihood and potential consequences of an attack.
                </p>
              </div>
              <div className="commercialServicesCol">
                <i
                  className="rvb-surveillance-alternative-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="commercialConsultingServicesColTitleContainer">
                  <h3>Identity access management</h3>
                </div>
                <p className="commercialConsultingServicesParagraph">
                  IAM focuses on managing user identities, their access
                  privileges, and ensuring secure authentication and
                  authorization processes. We help you enforce access controls
                  within your business in order to reduce the risk of
                  unauthorized access, and maintain regulatory compliance.
                </p>
              </div>
              <div className="commercialServicesCol">
                <i
                  className="rvb-threat-hunting-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="commercialConsultingServicesColTitleContainer">
                  <h3>
                    Threat <br></br>Hunting
                  </h3>
                </div>
                <p className="commercialConsultingServicesParagraph">
                  Proactive approach to cybersecurity that involves actively
                  searching for signs of malicious activity or threats within
                  your business' network or systems that may have gone
                  undetected by traditional security monitoring tools.
                </p>
              </div>
            </div>
            <div className="commercialConsultingServicesRow">
              <div className="commercialServicesCol">
                <i
                  className="rvb-digital-forensics-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="commercialConsultingServicesColTitleContainer">
                  <h3>Incident response and recovery</h3>
                </div>
                <p className="commercialConsultingServicesParagraph">
                  In the event of a cybersecurity incident or breach we assist
                  your business to respond effectively by developing incident
                  response plans specific to your companies systems, conduct
                  forensic investigations, contain the incident, and restore
                  affected systems and operations to normality.
                </p>
              </div>
              <div className="commercialServicesCol">
                <i
                  className="rvb-industry-supply-chain-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="commercialConsultingServicesColTitleContainer">
                  <h3>Supply chain security</h3>
                </div>
                <p className="commercialConsultingServicesParagraph">
                  Very likely your business relies on various vendors and
                  suppliers of technology or services, this could be an attack
                  vector.
                  <br></br>
                  Ensuring the security of the supply chain is critical to
                  prevent the introduction of compromised components that could
                  pose a risk to the overall cybersecurity posture.
                </p>
              </div>
              <div className="commercialServicesCol">
                <i
                  className="rvb-data-governance-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="commercialConsultingServicesColTitleContainer">
                  <h3>
                    Data <br></br>governance
                  </h3>
                </div>
                <p className="commercialConsultingServicesParagraph">
                  We assist your business adopt an overall strategy and enforce
                  guidelines for handling data throughout its lifecycle,
                  including collection, storage, usage, sharing, and disposal,
                  with a specific focus on maintaining confidentiality,
                  integrity, and availability.
                </p>
              </div>
            </div>
            <div className="commercialConsultingServicesRow">
              <div className="commercialServicesCol">
                <i
                  className="rvb-malicious-agent-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="commercialConsultingServicesColTitleContainer">
                  <h3>
                    Penetration<br></br>Testing
                  </h3>
                </div>
                <p className="commercialConsultingServicesParagraph">
                  This process refers to conducting controlled simulated attacks
                  (penetration tests) to evaluate the effectiveness of your
                  security measures. This helps uncover weaknesses before
                  malicious actors can exploit them.
                </p>
              </div>
              <div className="commercialServicesCol">
                <i
                  className="rvb-security-awareness-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="commercialConsultingServicesColTitleContainer">
                  <h3>Security awareness program</h3>
                </div>
                <p className="commercialConsultingServicesParagraph">
                  Human factors play a significant role in cybersecurity
                  therefore it has become crucial to train your business'
                  personnel and employees on best practices for cybersecurity.
                  <br></br>
                  We assist you perform awareness campaigns and training
                  programs to build an internal security-conscious culture.
                </p>
              </div>
              <div className="commercialServicesCol">
                <i
                  className="rvb-compliance-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="commercialConsultingServicesColTitleContainer">
                  <h3>Compliance and regulation control</h3>
                </div>
                <p className="commercialConsultingServicesParagraph">
                  Let's collaborate in order to define your business' compliance
                  objectives and achieve them by implementing standardized or
                  personalized guidelines to conduct effective cybersecurity
                  measures capable of meeting applicable laws and regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommercialServices;
