import React from "react";

import "../ResourcesContent.css";

const ResourcesArticleThree = () => {
  return (
    <div className="resourcesContentArea">
      <div className="resourcesContentLargeExclusive">
        <div className="resourcesInnerContent">
          <h1>ICS Cybersecurity with MITRE ATT&CK</h1>
          <div className="authorDetails">
            <div className="innerAuthorDetails">
              <i className="blogRvbIcon"></i>
              <span>By RVBionics Blog</span>
            </div>
            <div className="articleDate">
              <p>June 27th, 2023.</p>
            </div>
          </div>
          <div className="blogImageContainer mitreIcsArticleBg"></div>
          <div className="blogArticleText blogParagraph">
            MITRE ATT&CK (Adversarial Tactics, Techniques and Common Knowledge)
            is a technical knowledge base intended to categorize and understand
            cyber threats. In this particular article, we will focus on MITRE
            ATT&CK for Industrial Control Systems (ICS), which play a vital role
            in managing operations including SCADA, PLCs and HMIs.
          </div>
          <div className="blogArticleText blogParagraph">
            Interconnectivity is growing within industrial environments,
            therefore it is crucial to implement measures and provide training
            and awareness programs within your organization's personnel.
            Safeguarding these systems is the key for an effective cyberattack
            prevention, and it cannot wait any longer.
          </div>
          <div className="blogArticleText blogParagraph">
            Cyberattacks DO have physical implications, take for example, the
            power grid hack performed over Ukraine in December 2015 which
            resulted in power outages for more than 230000 people for up to 6
            hours.
          </div>
          <div className="blogArticleText blogParagraph">
            For that same reason, cybersecurity postures must be proactive and
            it is necessary to adopt a security-positive culture and mindset
            within your organization, take in consideration that no matter how
            strong your cybersecurity policies and tools are, the human factor
            could be the one leading to an incident.
          </div>
          <div className="blogArticleText blogParagraph">
            MITRE ATT&CK for ICS provides us with loads of knowledge to strength
            cybersecurity within critical infrastructure environments,
            comprising of:
          </div>
          <div className="blogArticleText blogParagraph">
            <ol>
              <li>
                <b>Threat Detection and Prevention:</b> by categorizing known
                tactics and techniques, it becomes possible to identify and
                monitor potential attack vectors, enabling early threat
                detection and response, minimizing potential damage.
              </li>
              <li>
                <b>Security Controls:</b> by mapping adversary techniques and
                assessing risks, we set comprehensive defense strategies under
                specific layers, strategies such as network segmentation, roles
                and permissions management, intrusion detection, unusual
                activity alerts, etc…
              </li>
              <li>
                <b>Incident Response:</b> thanks to the knowledge base of
                tactics and techniques, it is possible to create playbooks and
                response plans to mitigate cyberattacks as quickly as possible,
                minimizing downtime and prioritizing continuity of operations.
              </li>
              <li>
                <b>Security Awareness and Training:</b> since no system can be
                100% secure, it is key to provide proper security awareness
                training to your organization's personnel. MITRE ATT&CK provides
                us with valuable insights to tailor these programs and empower
                the workforce to recognize threats, follow best practices and
                report suspicious activities.
              </li>
            </ol>
          </div>
          <div className="blogArticleText blogParagraph">
            Today is better than tomorrow to start caring about cybersecurity.
            Critical infrastructure must be secured to ensure uninterrupted
            operations, -not just in production- but also, to preserve the
            quality of life and physical safety of our society.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesArticleThree;
