import React from 'react';

import Header from '../components/Header/Header';
import HeroCommercialConsulting from '../components/Hero/HeroCommercialConsulting';
import CommercialConsultingContent from '../components/ConsultingContent/CommercialConsultingContent';
import CommercialSecurityThreats from '../components/ThreatsContent/CommercialSecurityThreats';
import CommercialServices from '../components/ServicesConsulting/CommercialServices';
import CommercialConsultingWindow from '../components/Windows/CommercialConsultingWindow';
import Footer from '../components/Footer/Footer';

const CommercialConsultingScreen = () => {
    return (
        <div>
            <Header />
            <HeroCommercialConsulting />
            <CommercialConsultingContent />
            <CommercialSecurityThreats />
            <CommercialServices />
            <CommercialConsultingWindow />
            <Footer />
        </div>
    )
}

export default CommercialConsultingScreen;