import React from "react";

import Header from "../components/Header/Header";
//import HeaderTest from "../components/Header/HeaderTest";
import Hero from "../components/Hero/Hero";
import Showcase from "../components/Showcase/Showcase";
import SecuritySoftware from "../components/SecuritySoftware/SecuritySoftware";
import EnergyMgmt from "../components/EnergyMgmt/EnergyMgmt";
import Consulting from "../components/Consulting/Consulting";
import Blog from "../components/Blog/Blog";
import Footer from "../components/Footer/Footer";

const HomeScreen = () => {
  return (<div>
    <Header />
    <Hero />
    <Showcase />
    <SecuritySoftware />
    {/* <EnergyMgmt /> */}
    <Consulting />
    <Blog />
    <Footer />
  </div>);
};

export default HomeScreen;
