import React from "react";

import "./HeroContact.css";

const HeroContact = () => {
  return (
    <div>
      <div className="contactHeroArea">
        <div className="contactInnerHero">
        </div>
      </div>
    </div>
  );
};

export default HeroContact;
