import React from "react";

import "./Industries.css";

const elementBackground = {
  foodAndBev: require("../../img/industries/foodAndBev.png"),
  automotive: require("../../img/industries/automotive.png"),
  agroindustry: require("../../img/industries/agroindustry2.png"),
  energy: require("../../img/industries/energy2.png"),
  pharmaceutical: require("../../img/industries/pharmaceutical2.png"),
  mining: require("../../img/industries/mining3.png"),
  manufacturing: require("../../img/industries/manufacturing.png"),
  oilAndGas: require("../../img/industries/oil3.png"),
  chemical: require("../../img/industries/chemical.png"),
  water: require("../../img/industries/water.png"),
};

const Industries = () => {
  return (
    <div className="securicsIndustryArea">
      <div className="securicsIndustryLargeExclusive">
        <div className="securicsInnerIndustries">
          <div className="industriesTitle">
            <h1>Target Industries</h1>
          </div>
          <div className="securicsIndustryRow">
            <div
              className="securicsIndustryElement securicsElementOverlay"
              style={{
                backgroundPosition: `center`,
                backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.agroindustry})`,
              }}
            >
              <h3>Agroindustry</h3>
            </div>
            <div
              className="securicsIndustryElement securicsElementOverlay"
              style={{
                backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.automotive})`,
              }}
            >
              <h3>Automotive</h3>
            </div>
            <div
              className="securicsIndustryElement securicsElementOverlay"
              style={{
                backgroundPosition: `center`,
                backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.chemical})`,
              }}
            >
              <h3>Chemical</h3>
            </div>
            <div
              className="securicsIndustryElement securicsElementOverlay"
              style={{
                backgroundPosition: `center`,
                backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.energy})`,
              }}
            >
              <h3>Energy</h3>
            </div>
            <div
              className="securicsIndustryElement securicsElementOverlay"
              style={{
                backgroundPosition: `center`,
                backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.foodAndBev})`,
              }}
            >
              <h3>
                Food &<br></br>Beverage
              </h3>
            </div>
          </div>
          <div className="securicsIndustryRow">
            <div
              className="securicsIndustryElement securicsElementOverlay"
              style={{
                backgroundPosition: `center`,
                backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.manufacturing})`,
              }}
            >
              <h3>Manufacturing</h3>
            </div>
            <div
              className="securicsIndustryElement securicsElementOverlay"
              style={{
                backgroundPosition: `center`,
                backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.mining})`,
              }}
            >
              <h3>Mining</h3>
            </div>
            <div
              className="securicsIndustryElement securicsElementOverlay"
              style={{
                backgroundPosition: `center`,
                backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.oilAndGas})`,
              }}
            >
              <h3>Oil & Gas</h3>
            </div>
            <div
              className="securicsIndustryElement securicsElementOverlay"
              style={{
                backgroundPosition: `center`,
                backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.pharmaceutical})`,
              }}
            >
              <h3>Pharmaceutical</h3>
            </div>
            <div
              className="securicsIndustryElement securicsElementOverlay"
              style={{
                backgroundPosition: `center`,
                backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.water})`,
              }}
            >
              <h3>Water Treatment</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industries;
