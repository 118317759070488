import React from "react";

import "./Consulting.css";

const elementBackground = {
  business: require("../../img/businessCSaaS.jpg"),
  industry: require("../../img/industriesConsulting/iStock-1465056882.jpg"),
  government: require("../../img/governmentCSaaS.jpg"),
};

const Consulting = () => {
  return (
    <div>
      <div className="consultingArea">
        <div className="consultingInHomeLargeExclusive">
          <div className="descriptionContainer">
            <div className="headingContainer">
              <h2>CONSULTING</h2>
            </div>
            <h1>CyberSecurity as a Service</h1>
            <p>
              We provide a range of flexible services in hands of our team of
              experienced consultants in order to help you assess your current
              security measures, identify vulnerabilities, and develop a
              comprehensive plan to safeguard against cyber threats.
            </p>
          </div>
        </div>
        <div className="consultingHomeIndustriesLargeExclusive">
          <div className="industriesContainer">
            <a href="/#/commercial-security-services">
              <div
                className="industriesElement elementOverlay"
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.business})`,
                  backgroundPosition: `center`,
                }}
              >
                <h3>For Business</h3>
              </div>
            </a>
            <a href="/#/industrial-security-services">
              <div
                className="industriesElement elementOverlay"
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.industry})`,
                  backgroundPosition: `center`,
                }}
              >
                <h3>For Industries</h3>
              </div>
            </a>
            <a href="/#/public-organizations">
              <div
                className="industriesElement elementOverlay govAndDef"
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${elementBackground.government})`,
                }}
              >
                <h3>Government & Defense</h3>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consulting;
