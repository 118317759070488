import React from "react";

import Header from "../../components/Header/Header";
import HeroBlog from "../../components/Hero/HeroBlog";
import ResourcesArticleFour from "../../components/ResourcesContent/SeparatedPosts/FourthPost";
import Footer from "../../components/Footer/Footer";

const ResourcesArticleFourScreen = () => {
  return (
    <div>
      <Header />
      <HeroBlog />
      <ResourcesArticleFour />
      <Footer />
    </div>
  );
};

export default ResourcesArticleFourScreen;