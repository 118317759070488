import React, { useEffect } from "react";

import "./Modal.css";

const openPosition = {
  title: "Full-Stack Software Developer, Django + React",
  description: [
    "RVBionics is looking for a Full-Stack Developer to join our development team in Buenos Aires, Argentina. Our mission is to drive digital transformation in order to operate more efficiently, and securely by leveraging the latest technological advances targeting OT/ICS/IIoT systems and networks.",
    "We are looking to hire a Full-Stack Software Developer to collaborate in the development of the Client Side + Server Side applications for our upcoming Industrial Cybersecurity Software. You will be asked to develop and maintain APIs, optimize UX, ensure stability, etc...",
  ],
  responsibilities: [],
  requirements: [
    "At least one year of software development experience in a professional environment.",
    "At least two years of software development experience considering bootcamps, personal projects, etc...",
    "Familiarity with several of the following languages: Python, TypeScript, JavaScript, SQL, Lua, etc...",
    "Working knowledge of DjangoREST framework.",
    "Working knowledge of React Native.",
    "Knowledge of PostgreSQL or other relational database is a plus.",
    "Knowledge of any of the major cloud providers (AWS, Azure, GCP, etc...) is a plus.",
    "Understanding of Docker and containerization technologies is a plus.",
    "Problem-solving and analytical skills.",
    "Excellent communication skills and enthusiasm for collaboration.",
  ],
};

const FullStackDevModal = (props) => {
  if (!props.show) {
    document.body.style.overflow = "unset";
    return null;
  }

  if (props.show) {
    document.body.style.overflow = "hidden";
  }

  return (
    <div className="modal" onClick={props.onClose}>
      <div className="jobModalArea" onClick={(e) => e.stopPropagation()}>
        <div className="innerJobModal">
          <div className="closeModal">
            <button onClick={props.onClose} type="button">
              <svg
                width="20"
                height="20px"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="#fff" />
              </svg>
            </button>
          </div>
          <h2>{openPosition.title}</h2>
          <p>{openPosition.description[0]}</p>
          <p>{openPosition.description[1]}</p>
          <h3>Requirements:</h3>
          <ul>
            <li>{openPosition.requirements[0]}</li>
            <li>{openPosition.requirements[1]}</li>
            <li>{openPosition.requirements[2]}</li>
            <li>{openPosition.requirements[3]}</li>
            <li>{openPosition.requirements[4]}</li>
            <li>{openPosition.requirements[5]}</li>
            <li>{openPosition.requirements[6]}</li>
            <li>{openPosition.requirements[7]}</li>
            <li>{openPosition.requirements[8]}</li>
            <li>{openPosition.requirements[9]}</li>
          </ul>
          <h3>Interested in working at RVBionics?</h3>
          <p>
            Contact us via email introducing yourself at{" "}
            <a href="mailto:jobs@rvbionics.com">jobs@rvbionics.com</a> and
            attach a copy of your CV and any portfolio work you consider
            relevant.
            <br></br>
            Please include the type of role you’re after in the subject line. If
            you require any adjustments for interviews, please let us know and
            we will try and facilitate those needs as best we can.
          </p>
          <div className="applyButtonContainer">
            <div className="applyButton">
              <a href="mailto:jobs@rvbionics.com">
                <span>APPLY FOR THE POSITION</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullStackDevModal;
