import React from 'react';

import Header from '../components/Header/Header';
import HeroEnergyMgmt from '../components/Hero/HeroEnergyMgmt';
import EnergyMgmtDescription from '../components/ProductDescription/EnergyMgmtDescription';
import EnergyMgmtContent from '../components/EnergyMgmtContent/EnergyMgmtContent';
import Footer from '../components/Footer/Footer';
import Form from '../components/Form/Form';

const EnergyMgmtScreen = () => {
    return (
        <div>
            <Header />
            <HeroEnergyMgmt />
            <EnergyMgmtDescription />
            <EnergyMgmtContent />
            <Form />
            <Footer />
        </div>
    )
}

export default EnergyMgmtScreen