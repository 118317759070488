import React, { useRef, useEffect } from "react";

import AutoPlaySilentVideo from "../AutoplayVideo/AutoplayVideo";

import "./Hero.css";
import backgroundVideo from "../../media/hero.mp4";

const Hero = () => {
  return (
    <div>
      <div className="heroArea">
        <AutoPlaySilentVideo className="homeVideo" video={backgroundVideo} />
        <div className="homeHeroLargeExclusive">
          <div className="homeInnerHero">
            <h1>Digital Industry Software</h1>
            <p>
              Driving digital transformation for industries with cutting-edge
              software tools using real-time insights in order to streamline
              operations, to increase efficiency & productivity, and overall,
              generate growth.
            </p>
            <div className="homeHeroButtonContainer">
              <div className="homeHeroButtonMain">
                <a href="/#/company">
                  <p>Learn More</p>
                </a>
              </div>
              <div className="homeHeroButtonSecondary">
                <a href="/#/contact">
                  <p>Contact Us</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
