import React from "react";

import Header from "../components/Header/Header";
import HeroCareers from "../components/Hero/HeroCareers";
import OpenPositions from "../components/OpenPositions/OpenPositions";
import Form from '../components/Form/Form';
import Footer from "../components/Footer/Footer";

const CareerScreen = () => {
  return (<div>
    <Header />
    <HeroCareers />
    <OpenPositions />
    <Form />
    <Footer />
  </div>);
};

export default CareerScreen;