import React, { Component } from "react";

import "./Header.css";

const headerLogo = require("../../img/headerLogo.png");
const navbarIcon = require("../../img/icons/navIcon.png");
const closeIcon = require("../../img/icons/closeNavIcon.png");
const linkedInLogo = require("../../img/icons/linkedin.png");
const telegramLogo = require("../../img/icons/telegram.png");
const twitterLogo = require("../../img/icons/twitter.png");
const languageSelectorIcon = require("../../img/icons/globe.png");

class Header extends Component {
  state = {
    flag: true,
  };

  handleUpdate = () => {
    this.setState({ flag: !this.state.flag });
  };

  render() {
    return (
      <div className="header">
        <div className="top_line">
          <div
            className="headerLargeExclusive"
            style={{ justifyContent: "flex-end" }}
          >
            {/* */}
            <div className="socialMediaContainer">
              <div className="socialMediaElement">
                <a href="https://www.linkedin.com/company/rvbionics/">
                  <img
                    src={linkedInLogo}
                    alt=""
                    width={"30px"}
                    height={"30px"}
                  />
                </a>
              </div>
              <div className="socialMediaElement">
                <a href="https://www.twitter.com/rvbionics/">
                  <img
                    src={twitterLogo}
                    alt=""
                    width={"30px"}
                    height={"27px"}
                  />
                </a>
              </div>
              <div className="socialMediaElement">
                <a href="https://t.me/+7cZcGre2RrI4YzAx">
                  <img
                    src={telegramLogo}
                    alt=""
                    width={"30px"}
                    height={"30px"}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* BEGINNING DESKTOP NAVBAR */}
        <div className="navDesktopExclusive">
          <div className="innerHeader">
            <div className="wrapper">
              <div className="headerLogo">
                <a href="/">
                  <img src={headerLogo} alt={""} />
                </a>
              </div>
              <nav>
                <ul>
                  <li className="dropdown">
                    <ul>
                      <li className="navButton">
                        <a href="/#/">
                          <p>Products ▾</p>
                        </a>
                        <div className="dropDownMenu">
                          <div className="dropDownMenuElementContainer">
                            <a href="/#/industrial-cybersecurity">
                              <p>Industrial Cybersecurity</p>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  {/* 
                  <li className="dropdown">
                    <ul>
                      <li className="navButton">
                        <a href="/#/">
                          <p>Solutions ▾</p>
                        </a>
                        <div className="dropDownMenu">
                          <div className="dropDownMenuElementContainer">
                            <a href="/#/energy-management">
                              <p>Energy Management</p>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  */}
                  <li className="dropdown">
                    <ul>
                      <li className="navButton">
                        <a href="/#/">
                          <p>Services ▾</p>
                        </a>
                        <div
                          className="dropDownMenu"
                          style={{ textAlign: "left", paddingLeft: "20px" }}
                        >
                          <div className="dropDownMenuElementContainer">
                            <a href="/#/industrial-security-services">
                              <p>For Industries</p>
                            </a>
                          </div>
                          <div className="dropDownMenuElementContainer">
                            <a href="/#/commercial-security-services">
                              <p>For Business</p>
                            </a>
                          </div>
                          <div className="dropDownMenuElementContainer">
                            <a href="/#/public-organizations">
                              <p>For Government</p>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="navButton">
                    <a href="/#/resources">
                      <p>Resources</p>
                    </a>
                  </li>
                  <li className="navButton">
                    <a href="/#/careers">
                      <p>Careers</p>
                    </a>
                  </li>
                  <li className="navButton">
                    <a href="/#/company">
                      <p>Company</p>
                    </a>
                  </li>
                </ul>
              </nav>
              <div
                className="languageSelectorContainer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "90%",
                }}
              >
                <div className="langPreviewElement">
                  <li className="langDropdown">
                    <div className="innerDropdownContainer">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.6 16.2C12.7974 16.2 16.2 12.7974 16.2 8.6C16.2 4.40264 12.7974 1 8.6 1C4.40264 1 1 4.40264 1 8.6C1 12.7974 4.40264 16.2 8.6 16.2Z"
                          stroke="#fff"
                          stroke-width="0.75"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M8.59961 16.2C10.8087 16.2 12.5996 12.7974 12.5996 8.6C12.5996 4.40264 10.8087 1 8.59961 1C6.39047 1 4.59961 4.40264 4.59961 8.6C4.59961 12.7974 6.39047 16.2 8.59961 16.2Z"
                          stroke="#fff"
                          stroke-width="0.75"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M8.59961 15.9V1"
                          stroke="#fff"
                          stroke-width="0.75"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M1.09961 8.59998H16.0996"
                          stroke="#fff"
                          stroke-width="0.75"
                          stroke-miterlimit="10"
                        />
                      </svg>
                      <span style={{ color: "white" }}>EN ▾</span>
                    </div>
                    <div className="langDropDownMenu">
                      <div className="langDropDownMenuElementContainer">
                        <a href="https://rvbionics.com/es/">
                          <p>ES</p>
                        </a>
                        {/* <a href="https://rvbionics.com/es/">
                          <p>PT</p>
    </a> */}
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navMobileExclusive">
          <div className="innerHeader">
            <div className={"wrapper"}>
              <div className="navBarUpperContainer">
                <div className="headerLogo">
                  <a href="/">
                    <img src={headerLogo} alt={""} />
                  </a>
                </div>
                <div
                  className="languageSelectorContainer"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxHeight: "90%",
                  }}
                >
                  <div className="langPreviewElement">
                    <li className="langDropdown">
                      <div className="innerDropdownContainer">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.6 16.2C12.7974 16.2 16.2 12.7974 16.2 8.6C16.2 4.40264 12.7974 1 8.6 1C4.40264 1 1 4.40264 1 8.6C1 12.7974 4.40264 16.2 8.6 16.2Z"
                            stroke="#fff"
                            stroke-width="0.75"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M8.59961 16.2C10.8087 16.2 12.5996 12.7974 12.5996 8.6C12.5996 4.40264 10.8087 1 8.59961 1C6.39047 1 4.59961 4.40264 4.59961 8.6C4.59961 12.7974 6.39047 16.2 8.59961 16.2Z"
                            stroke="#fff"
                            stroke-width="0.75"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M8.59961 15.9V1"
                            stroke="#fff"
                            stroke-width="0.75"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M1.09961 8.59998H16.0996"
                            stroke="#fff"
                            stroke-width="0.75"
                            stroke-miterlimit="10"
                          />
                        </svg>
                        <span style={{ color: "white" }}>EN ▾</span>
                      </div>
                      <div className="langDropDownMenu">
                        <div className="langDropDownMenuElementContainer">
                          <a href="https://rvbionics.com/es/">
                            <p>ES</p>
                          </a>
                          {/* <a href="https://rvbionics.com/es/">
                          <p>PT</p>
    </a> */}
                        </div>
                      </div>
                    </li>
                  </div>
                </div>
                <button className="navigationIcons" onClick={this.handleUpdate}>
                  <div
                    className={`btn ${
                      this.state.flag ? "menuIconActive" : "menuIconHidden"
                    }`}
                  >
                    <img src={navbarIcon} alt={""} />
                  </div>
                  <div
                    className={`btn ${
                      this.state.flag ? "closeIconHidden" : "closeIconactive"
                    }`}
                  >
                    <img src={closeIcon} alt={""} />
                  </div>
                </button>
              </div>
              <nav
                className={`btn ${
                  this.state.flag
                    ? "navigationBarHidden"
                    : "navigationBarActive"
                }`}
              >
                <ul>
                  <li className="dropdown">
                    <ul>
                      <li className="navButton">
                        <a href="/#/">
                          <p>Products ▾</p>
                        </a>
                        <div className="dropDownMenu">
                          <div className="dropDownMenuElementContainer">
                            <a href="/#/industrial-cybersecurity">
                              <p>Industrial Cybersecurity</p>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <ul>
                      <li className="navButton">
                        <a href="/#/">
                          <p>Solutions ▾</p>
                        </a>
                        <div className="dropDownMenu">
                          <div className="dropDownMenuElementContainer">
                            <a href="/#/energy-management">
                              <p>Energy Management</p>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <ul>
                      <li className="navButton">
                        <a href="/#/">
                          <p>Services ▾</p>
                        </a>
                        <div className="dropDownMenu" style={{}}>
                          <div className="dropDownMenuElementContainer">
                            <a href="/#/industrial-security-services">
                              <p>For Industries</p>
                            </a>
                          </div>
                          <div className="dropDownMenuElementContainer">
                            <a href="/#/commercial-security-services">
                              <p>For Business</p>
                            </a>
                          </div>
                          <div className="dropDownMenuElementContainer">
                            <a href="/#/public-organizations">
                              <p>For Government</p>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="navButton">
                    <a href="/#/resources">
                      <p>Resources</p>
                    </a>
                  </li>
                  <li className="navButton">
                    <a href="/#/careers">
                      <p>Careers</p>
                    </a>
                  </li>
                  <li className="navButton">
                    <a href="/#/company">
                      <p>Company</p>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
