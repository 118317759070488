import React from "react";

import "./PublicOrganizationServices.css";
import "../../vectors/industrialVectors.css";
import "../../vectors/cybersecVectors.css";
import "../../vectors/industrySecVectors.css";

// const antennaGraph = require("../../img/publicOrganizations/graphs/antenna.png");
const fighterJetGraph = require("../../img/publicOrganizations/graphs/fighterJet.png");

const PublicOrganizationServices = () => {
  return (
    <div className="publicOrganizationServicesArea">
      <div className="publicOrganizationServicesGridListLargeExclusive">
        <div className="publicOrganizationServicesInnerGridListArea">
          <div className="desktopExclusive">
            <div className="publicOrganizationServicesUpperContainer">
              <div className="publicOrganizationsTitleContainer">
                <h1>
                  The path to <br></br>cyber resilience
                </h1>
              </div>
              <div className="publicOrganizationsGraphContainer">
                <img src={fighterJetGraph} alt={""} />
              </div>
            </div>
          </div>
          <div className="mobileExclusive">
            <div className="publicOrganizationServicesUpperContainer">
              <div className="publicOrganizationsGraphContainer">
                <img src={fighterJetGraph} alt={""} />
              </div>
              <div className="publicOrganizationsTitleContainer" style={{maxWidth: "100%"}}>
                <h1>
                  The path to cyber resilience
                </h1>
              </div>
            </div>
          </div>
          <div className="publicOrganizationServicesListContainer">
            <div className="publicOrganizationServicesRow">
              <div className="publicOrganizationServicesCol">
                <i
                  className="rvb-attack-surface-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="publicOrganizationServicesColTitleContainer">
                  <h3>Attack surface analysis</h3>
                </div>
                <p className="publicOrganizationServicesParagraph">
                  The attack surface represents the sum of all possible avenues
                  an attacker can use to gain unauthorized access, compromise,
                  or disrupt the government's IT assets, including networks,
                  applications, databases, and endpoints.
                </p>
              </div>
              <div className="publicOrganizationServicesCol">
                <i
                  className="rvb-risk-assessment-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="publicOrganizationServicesColTitleContainer">
                  <h3>Risk assessment and management</h3>
                </div>
                <p className="publicOrganizationServicesParagraph">
                  Conducting comprehensive risk assessments is crucial to
                  identify vulnerabilities, potential risks, and the impact of
                  cyber attacks on government systems.
                  <br></br>
                  <br></br>
                  This process helps prioritize security measures based on the
                  likelihood and potential consequences of an attack.
                </p>
              </div>
              <div className="publicOrganizationServicesCol">
                <i
                  className="rvb-surveillance-alternative-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="publicOrganizationServicesColTitleContainer">
                  <h3>Identity access management</h3>
                </div>
                <p className="publicOrganizationServicesParagraph">
                  Government and defense organizations must enforce strong
                  access controls, encryption, and multi-factor authentication
                  mechanisms help protect sensitive data and secure information
                  technology (IT) infrastructures.
                </p>
              </div>
            </div>
            <div className="publicOrganizationServicesRow">
              <div className="publicOrganizationServicesCol">
                <i
                  className="rvb-digital-forensics-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="publicOrganizationServicesColTitleContainer">
                  <h3>Incident response and recovery</h3>
                </div>
                <p className="publicOrganizationServicesParagraph">
                  In the event of a cyber attack, government and defense
                  entities need well-defined incident response plans. These
                  plans outline the steps to detect, contain, mitigate, and
                  recover from an attack while minimizing damage and maintaining
                  operations.
                </p>
              </div>
              <div className="publicOrganizationServicesCol">
                <i
                  className="rvb-industry-supply-chain-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="publicOrganizationServicesColTitleContainer">
                  <h3>Supply chain security</h3>
                </div>
                <p className="publicOrganizationServicesParagraph">
                  The government and defense sectors rely on a complex supply
                  chain involving vendors and contractors. Ensuring the security
                  of the supply chain is critical to prevent the introduction of
                  compromised hardware or software components that could pose a
                  risk to the overall cybersecurity posture.
                </p>
              </div>
              <div className="publicOrganizationServicesCol">
                <i
                  className="rvb-data-governance-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="publicOrganizationServicesColTitleContainer">
                  <h3>
                    Data<br></br>governance
                  </h3>
                </div>
                <p className="publicOrganizationServicesParagraph">
                  We assist governments adopt an overall strategy and enforce
                  guidelines for handling data throughout its lifecycle,
                  including collection, storage, usage, sharing, and disposal,
                  with a specific focus on maintaining confidentiality,
                  integrity, and availability.
                </p>
              </div>
            </div>
            <div className="publicOrganizationServicesRow">
              <div className="publicOrganizationServicesCol">
                <i
                  className="rvb-global-surveillance-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="publicOrganizationServicesColTitleContainer">
                  <h3>Collective defense and intelligence</h3>
                </div>
                <p className="publicOrganizationServicesParagraph">
                  Government and defense entities collaborate with each other,
                  as well as with industry partners and international allies, to
                  share threat intelligence, best practices, and mitigation
                  strategies. Such collaboration helps strengthen cybersecurity
                  defenses collectively.
                </p>
              </div>
              <div className="publicOrganizationServicesCol">
                <i
                  className="rvb-security-awareness-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="publicOrganizationServicesColTitleContainer">
                  <h3>Security awareness program</h3>
                </div>
                <p className="publicOrganizationServicesParagraph">
                  Human factors play a significant role in cybersecurity. It is
                  crucial to train government employees and defense personnel on
                  best practices for cybersecurity.
                  <br></br>
                  We assist public organizations perform awareness campaigns and
                  training programs to build an internal security-conscious
                  culture.
                </p>
              </div>
              <div className="publicOrganizationServicesCol">
                <i
                  className="rvb-compliance-icon"
                  style={{ width: "70px", height: "70px" }}
                ></i>
                <div className="publicOrganizationServicesColTitleContainer">
                  <h3>Compliance and regulation control</h3>
                </div>
                <p className="publicOrganizationServicesParagraph">
                  We collaborate with governments and other public organizations
                  in order to define their compliance objectives and achieve
                  them by implementing standardized or personalized guidelines
                  to conduct effective cybersecurity measures capable of meeting
                  applicable laws and regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicOrganizationServices;
