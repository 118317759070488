import React from "react";

import Header from "../components/Header/Header";
import HeroPublicOrganizations from "../components/Hero/HeroPublicOrganizations";
import PublicOrganizationsContent from "../components/PublicOrganizationsContent/PublicOrganizationsContent";
import PublicOrganizationSecurityThreats from "../components/ThreatsContent/PublicOrganizationSecurityThreats";
import PublicOrganizationServices from "../components/ServicesConsulting/PublicOrganizationServices";
import PublicOrganizationsWindow from "../components/Windows/PublicOrganizationsWindow";
import Footer from "../components/Footer/Footer";

const PublicOrganizationsConsultingScreen = () => {

  return (
    <div>
      <Header />
      <HeroPublicOrganizations />
      <PublicOrganizationsContent />
      <PublicOrganizationSecurityThreats />
      <PublicOrganizationServices />
      <PublicOrganizationsWindow />
      <Footer />
    </div>
  );
};

export default PublicOrganizationsConsultingScreen;
