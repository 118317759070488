import React from "react";

import "./HeroPrivacyPolicy.css";

const HeroPrivacyPolicy = () => {
  return (
    <div>
      <div className="privacyPolicyHeroArea">
        <div className="privacyPolicyHeroLargeExclusive">
          <div className="privacyPolicyInnerHero">
            <h1 className="mainPrivacyPolicyTitle">Privacy Policy</h1>
            <span className="mainPrivacyPolicyLead">Last Updated: May 14th, 2023.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroPrivacyPolicy;