import React from "react";

import "./EnergyMgmtContent.css";

const contentImages = {
  dezemBoard: require("../../img/dezemContent/dezemboard.jpg"),
  dezemVis: require("../../img/dezemContent/dezemvis.jpg"),
  dezemReport: require("../../img/dezemContent/dezemreport.jpg"),
};

const EnergyMgmtContent = () => {
  return (
    <div className="energyMgmtContentArea">
      <div className="desktopExclusive">
        <div className="energyMgmtContentRow energyMgmtContentLargeExclusive">
          <div className="colEnergyMgmtContentImageContainer dezemBoardBg"></div>
          <div
            className="colEnergyMgmtContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="titleDecorationLine" />
            <h3>deZemBoard</h3>
            <p>
              Configure individual Dashboards of diagrams, messages, images or
              more complex components with a few clicks to support your energy
              management or other data-based projects. Use the Dashboard as a
              portal for all modules of the deZem Energy Controlling Software.
              From there, click on to gain more in-depth insights, study
              abnormalities, generate reports or administer your energy
              controlling system.
            </p>
          </div>
        </div>
      </div>
      <div className="mobileExclusive">
        <div className="energyMgmtContentRow">
          <div
            className="colEnergyMgmtContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="titleDecorationLine" />
            <h3>deZemBoard</h3>
            <p>
              Configure individual Dashboards of diagrams, messages, images or
              more complex components with a few clicks to support your energy
              management or other data-based projects. Use the Dashboard as a
              portal for all modules of the deZem Energy Controlling Software.
              From there, click on to gain more in-depth insights, study
              abnormalities, generate reports or administer your energy
              controlling system.
            </p>
          </div>
          <div className="colEnergyMgmtContentImageContainer dezemBoardBg"></div>
        </div>
      </div>
      <div className="energyMgmtContentRow energyMgmtContentLargeExclusive">
        <div
          className="colEnergyMgmtContentBlockContainer"
          style={{ paddingRight: "80px" }}
        >
          <div className="titleDecorationLine" />
          <h3>deZemVis</h3>
          <p>
            deZemVis is a web-based, interactive visualisation software with
            numerous useful and innovative analysis functions. It is the leader
            in terms of user-friendliness, analytical skill, performance and
            flexibility for effective energy controlling! Used by international
            companies, large public property administrations, universities, data
            centers and many others.
          </p>
        </div>
        <div className="colEnergyMgmtContentImageContainer dezemVisBg"></div>
      </div>
      <div className="desktopExclusive">
        <div className="energyMgmtContentRow energyMgmtContentLargeExclusive">
          <div className="colEnergyMgmtContentImageContainer dezemReportBg"></div>
          <div
            className="colEnergyMgmtContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="titleDecorationLine" />
            <h3>deZemReport</h3>
            <p>
              deZemReport is the software module for configuring energy and
              environmental reports that are sent automatically and regularly by
              e-mail to:
              <br></br>
              <br></br>
              <li>Keep an eye on costs</li>
              <li>
                Stay informed about the development of key performance
                indicators
              </li>
              <li>Compare current consumption with the previous year</li>
              <li>Sustainable monitoring of savings successes</li>
            </p>
          </div>
        </div>
      </div>
      <div className="mobileExclusive">
        <div className="energyMgmtContentRow">
          <div
            className="colEnergyMgmtContentBlockContainer"
            style={{ paddingLeft: "80px" }}
          >
            <div className="titleDecorationLine" />
            <h3>deZemReport</h3>
            <p>
              deZemReport is the software module for configuring energy and
              environmental reports that are sent automatically and regularly by
              e-mail to:
              <br></br>
              <br></br>
              <li>Keep an eye on costs</li>
              <li>
                Stay informed about the development of key performance
                indicators
              </li>
              <li>Compare current consumption with the previous year</li>
              <li>Sustainable monitoring of savings successes</li>
            </p>
          </div>
          <div className="colEnergyMgmtContentImageContainer dezemReportBg"></div>
        </div>
      </div>
      <div className="energyMgmtContentRow energyMgmtContentLargeExclusive">
        <div
          className="colEnergyMgmtContentBlockContainer"
          style={{ paddingRight: "80px" }}
        >
          <div className="titleDecorationLine" />
          <h3>deZemAd</h3>
          <p>
            deZemAd is the software module with which our customers configure
            and further develop their energy controlling system independently -
            completely or partially, according to their wishes.
            <br></br>
            <br></br>
            With the multi-client capable role concept, you distribute the
            rights for managing and using the energy controlling system broadly
            or narrowly over any number of people.
          </p>
        </div>
        <div className="colEnergyMgmtContentImageContainer dezemAdBg"></div>
      </div>
    </div>
  );
};

export default EnergyMgmtContent;
