import React, { useEffect } from "react";

import "./Modal.css";

const openPosition = {
  title: "Penetration Tester / Red Teamer",
  description: [
    "RVBionics is looking for a Penetration Tester / Red Teamer to join our Security Operations Center in Santiago, Chile. Our mission is to drive digital transformation in order to operate more efficiently, and securely by leveraging the latest technological advances targeting OT/ICS/IIoT systems and networks.",
    "We are looking to hire an experienced Penetration Tester / Red Teamer to take an important role within our Security Operations Center exercising the lead on Adversary Operations in our consulting projects as well as performing penetration attacks on our existing infrastructure to contributing directly to their improvement. Throughout the process you will be asked to document your actions in details and generate reports on the performed tasks.",
  ],
  responsibilities: [
    "Lead adversary operations in our consulting projects.",
    "Analyze attacks and perform reverse engineering techniques to define vulnerabilities.",
    "Create new testing methods to identify vulnerabilities.",
    "Identify weaknesses within existing systems, and simulate attacks on allowed networks.",
    "Write documentation and reports for each project  as a whole in addition of safety-critical tasks.",
  ],
  requirements: [
    "High proficiency in the Linux command line and Kali Linux.",
    "At least two years of experience in networking / cybersecurity.",
    "Proficiency in several of the following languages: Python, SQL, PowerShell, Lua, etc...",
    "Advanced understanding of network protocols.",
    "Understanding of reverse engineering techniques.",
    "Problem-solving and analytical skills.",
    "Excellent communication skills and enthusiasm for collaboration.",
    "Ability to “think like the enemy” and to be proactive in order to combat threats effectively and even anticipate them.",
  ],
};

const PenetrationTesterModal = (props) => {
  if (!props.show) {
    document.body.style.overflow = "unset";
    return null;
  }

  if (props.show) {
    document.body.style.overflow = "hidden";
  }

  return (
    <div className="modal" onClick={props.onClose}>
      <div className="jobModalArea" onClick={(e) => e.stopPropagation()}>
        <div className="innerJobModal">
          <div className="closeModal">
            <button onClick={props.onClose} type="button">
              <svg
                width="20"
                height="20px"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="#fff" />
              </svg>
            </button>
          </div>
          <h2>{openPosition.title}</h2>
          <p>{openPosition.description[0]}</p>
          <p>{openPosition.description[1]}</p>
          <h3>Responsibilities:</h3>
          <ul>
            <li>{openPosition.responsibilities[0]}</li>
            <li>{openPosition.responsibilities[1]}</li>
            <li>{openPosition.responsibilities[2]}</li>
            <li>{openPosition.responsibilities[3]}</li>
          </ul>
          <h3>Requirements:</h3>
          <ul>
            <li>{openPosition.requirements[0]}</li>
            <li>{openPosition.requirements[1]}</li>
            <li>{openPosition.requirements[2]}</li>
            <li>{openPosition.requirements[3]}</li>
            <li>{openPosition.requirements[4]}</li>
            <li>{openPosition.requirements[5]}</li>
            <li>{openPosition.requirements[6]}</li>
            <li>{openPosition.requirements[7]}</li>
          </ul>
          <h3>Interested in working at RVBionics?</h3>
          <p>
            Contact us via email introducing yourself at{" "}
            <a href="mailto:jobs@rvbionics.com">jobs@rvbionics.com</a> and
            attach a copy of your CV and any portfolio work you consider
            relevant.
            <br></br>
            Please include the type of role you’re after in the subject line. If
            you require any adjustments for interviews, please let us know and
            we will try and facilitate those needs as best we can.
          </p>
          <div className="applyButtonContainer">
            <div className="applyButton">
              <a href="mailto:jobs@rvbionics.com">
                <span>APPLY FOR THE POSITION</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PenetrationTesterModal;
