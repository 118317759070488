import React, { useState } from "react";

import "./OpenPositions.css";

import SoftwareEngineerModal from "../Modal/SoftwareEngineerModal";
import FullStackDevModal from "../Modal/FullStackDevModal";
import PenetrationTesterModal from "../Modal/PenetrationTesterModal";

const isAvailable = true;

const OpenPositions = () => {
  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);

  return (
    <div className="careersOpArea">
      <div className="careersOpContainer">
        <div className="careersUpperSection">
          <h2>Open Positions</h2>
        </div>
        {isAvailable ? (
          <div className="careersJobOffersSection">
            <div className="jobElementsContainer">
              <div className="jobElement">
                <div className="jobElementDetails">
                  <h3>Software Engineer</h3>
                  <h5>argentina | engineering</h5>
                </div>
                <button onClick={() => setShowOne(true)}>
                  <i className="plusIcon"></i>
                </button>
                <SoftwareEngineerModal
                  show={showOne}
                  onClose={() => setShowOne(false)}
                />
              </div>
              <div className="jobElement">
                <div className="jobElementDetails">
                  <h3>Full-Stack Developer</h3>
                  <h5>argentina | engineering</h5>
                </div>
                <button onClick={() => setShowTwo(true)}>
                  <i className="plusIcon"></i>
                </button>
                <FullStackDevModal
                  show={showTwo}
                  onClose={() => setShowTwo(false)}
                />
              </div>
              <div className="jobElement">
                <div className="jobElementDetails">
                  <h3>Penetration Tester</h3>
                  <h5>chile | cybersecurity</h5>
                </div>
                <button onClick={() => setShowThree(true)}>
                  <i className="plusIcon"></i>
                </button>
                <PenetrationTesterModal
                  show={showThree}
                  onClose={() => setShowThree(false)}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="noAvailableCareersSection">
            <h5>Currently there are no open positions...</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenPositions;
