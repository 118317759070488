import React from "react";

import "./ResourcesContent.css";

const ResourcesContent = () => {
  return (
    <div className="resourcesContentArea">
      <div className="resourcesContentLargeExclusive">
        <div className="resourcesInnerContent">
          <h1>The Human Factor in Cybersecurity</h1>
          <div className="authorDetails">
            <div className="innerAuthorDetails">
              <i className="blogRvbIcon"></i>
              <span>By RVBionics Blog</span>
            </div>
            <div className="articleDate">
              <p>August 9th, 2023.</p>
            </div>
          </div>
          <div className="blogImageContainer humanFactorArticleBg"></div>
          <div className="blogArticleText blogParagraph">
            In the modern days, industrial plants have more interconnected
            processes running than ever before, and this, has turned them
            increasingly vulnerable to multiple types of cyber threats, and all
            of us, mankind as a whole, are paying a lot of attention to the
            implementation of advanced technologies to address this issue, which
            is key to build a security-positive environment. However, all this
            means nothing if we overlook the human factor.
          </div>
          <div className="blogArticleText blogParagraph">
            The human factor refers to how industrial -and every other
            organization- personnel play a crucial role in the effectiveness of
            our defense measures and policies against cyber threats, being the
            first line of defense themselves.
          </div>
          <div className="blogArticleText blogParagraph">
            Conducting appropriate cybersecurity awareness campaigns regularly
            must be a high priority, especially in this current period of
            constant technological advances which has lead to an always evolving
            threat landscape, with the potential of causing physical damage such
            as production disruptions, safety hazards, financial losses, between
            others.
          </div>
          <div className="blogArticleText blogParagraph">
            A security-fit and conscious workforce should be qualified enough to
            identify and mitigate a considerable portion of cyber risks, such as
            social engineering attacks and unsafe practices. This is achievable
            by providing broad and regular cybersecurity training, but still
            means nothing if a company's leadership is not committed.
            Cybersecurity training must be acompanied by a comprehensive
            incident response policy and stimulated by recognition-and-reward
            policies, otherwise, you'll often find a decrease of consciousness
            after some time.
          </div>
          <div className="blogArticleText blogParagraph">
            Cybersecurity is a contrniuous process, and especially in an
            industrial context, no one can afford to lower their guard. It is
            also collaborative, a joint-arround defense is preferrable, a good
            cybersecurity training should promote learning and improve
            communication for the sake of security, it is dangerous to go alone.
          </div>
        </div>
        <div className="resourcesInnerContent">
          <h1>ICS Cybersecurity with MITRE ATT&CK</h1>
          <div className="authorDetails">
            <div className="innerAuthorDetails">
              <i className="blogRvbIcon"></i>
              <span>By RVBionics Blog</span>
            </div>
            <div className="articleDate">
              <p>June 27th, 2023.</p>
            </div>
          </div>
          <div className="blogImageContainer mitreIcsArticleBg"></div>
          <div className="blogArticleText blogParagraph">
            MITRE ATT&CK (Adversarial Tactics, Techniques and Common Knowledge)
            is a technical knowledge base intended to categorize and understand
            cyber threats. In this particular article, we will focus on MITRE
            ATT&CK for Industrial Control Systems (ICS), which play a vital role
            in managing operations including SCADA, PLCs and HMIs.
          </div>
          <div className="blogArticleText blogParagraph">
            Interconnectivity is growing within industrial environments,
            therefore it is crucial to implement measures and provide training
            and awareness programs within your organization's personnel.
            Safeguarding these systems is the key for an effective cyberattack
            prevention, and it cannot wait any longer.
          </div>
          <div className="blogArticleText blogParagraph">
            Cyberattacks DO have physical implications, take for example, the
            power grid hack performed over Ukraine in December 2015 which
            resulted in power outages for more than 230000 people for up to 6
            hours.
          </div>
          <div className="blogArticleText blogParagraph">
            For that same reason, cybersecurity postures must be proactive and
            it is necessary to adopt a security-positive culture and mindset
            within your organization, take in consideration that no matter how
            strong your cybersecurity policies and tools are, the human factor
            could be the one leading to an incident.
          </div>
          <div className="blogArticleText blogParagraph">
            MITRE ATT&CK for ICS provides us with loads of knowledge to strength
            cybersecurity within critical infrastructure environments,
            comprising of:
          </div>
          <div className="blogArticleText blogParagraph">
            <ol>
              <li>
                <b>Threat Detection and Prevention:</b> by categorizing known
                tactics and techniques, it becomes possible to identify and
                monitor potential attack vectors, enabling early threat
                detection and response, minimizing potential damage.
              </li>
              <li>
                <b>Security Controls:</b> by mapping adversary techniques and
                assessing risks, we set comprehensive defense strategies under
                specific layers, strategies such as network segmentation, roles
                and permissions management, intrusion detection, unusual
                activity alerts, etc…
              </li>
              <li>
                <b>Incident Response:</b> thanks to the knowledge base of
                tactics and techniques, it is possible to create playbooks and
                response plans to mitigate cyberattacks as quickly as possible,
                minimizing downtime and prioritizing continuity of operations.
              </li>
              <li>
                <b>Security Awareness and Training:</b> since no system can be
                100% secure, it is key to provide proper security awareness
                training to your organization's personnel. MITRE ATT&CK provides
                us with valuable insights to tailor these programs and empower
                the workforce to recognize threats, follow best practices and
                report suspicious activities.
              </li>
            </ol>
          </div>
          <div className="blogArticleText blogParagraph">
            Today is better than tomorrow to start caring about cybersecurity.
            Critical infrastructure must be secured to ensure uninterrupted
            operations, -not just in production- but also, to preserve the
            quality of life and physical safety of our society.
          </div>
        </div>
        <div className="resourcesInnerContent">
          <h1>The Rising Relevance of Cybersecurity</h1>
          <div className="authorDetails">
            <div className="innerAuthorDetails">
              <i className="blogRvbIcon"></i>
              <span>By RVBionics Blog</span>
            </div>
            <div className="articleDate">
              <p>May 25th, 2023.</p>
            </div>
          </div>
          <div className="blogImageContainer relevanceCybersecArticleBg"></div>
          <div className="blogArticleText blogParagraph">
            In an era of ongoing technological advancements and a hyperconnected
            world, the relevance of cybersecurity has reached new heights. As we
            increasingly rely on digital platforms for communication, financial
            transactions, and everyday tasks, the threat landscape has expanded
            exponentially. With cybercrime becoming more sophisticated, it is
            imperative that individuals, businesses, and organizations take
            proactive steps to safeguard their digital assets. In this article,
            we will explore why cybersecurity is becoming increasingly relevant
            and why it is crucial to take action as soon as possible.
          </div>
          <div className="blogArticleText blogParagraph">
            Cyber threats have become more prevalent, diverse, and severe in
            recent years. The rise of advanced persistent threats (APTs),
            ransomware attacks, data breaches, and identity theft has
            highlighted the vulnerabilities present in our digital ecosystems.
            These threats not only impact individuals but also have far-reaching
            consequences for businesses, governments, and critical
            infrastructure. As the digital landscape expands and becomes more
            complex, the opportunities for cybercriminals to exploit
            vulnerabilities also increase.
          </div>
          <div className="blogArticleText blogParagraph">
            The proliferation of internet-connected devices, commonly known as
            the Internet of Things (IoT), has further heightened the need for
            robust cybersecurity measures. From smart homes and wearable devices
            to industrial control systems, the interconnectivity of these
            devices creates a vast attack surface for cybercriminals to exploit.
            Weaknesses in one device can potentially compromise an entire
            network, leading to devastating consequences. Taking early action to
            secure these devices is crucial to mitigate risks and protect
            sensitive information.
          </div>
          <div className="blogArticleText blogParagraph">
            Cybercrime is becoming more organized, sophisticated, and
            well-funded. They employ advanced techniques such as social
            engineering, phishing attacks, and zero-day exploits to infiltrate
            systems and steal valuable data. The emergence of dark web
            marketplaces and ransomware-as-a-service (RaaS) models has made it
            easier for even novice criminals to carry out cyberattacks. With the
            potential for significant financial gains and minimal risk of
            detection, cybercriminals are highly motivated to exploit
            vulnerabilities.
          </div>
          <div className="blogArticleText blogParagraph">
            The fallout from a successful cyberattack can be devastating. Beyond
            the immediate financial losses associated with data breaches or
            ransom payments, organizations may face long-term reputational
            damage. Trust and confidence in a brand can be severely harmed,
            leading to a loss of customers and business opportunities. The costs
            associated with remediation, legal proceedings, and regulatory fines
            can be astronomical. By taking early action to bolster cybersecurity
            defenses, organizations can reduce the risk of such damaging
            incidents and safeguard their bottom line.
          </div>
          <div className="blogArticleText blogParagraph">
            Governments and regulatory bodies around the world are enacting
            stringent cybersecurity regulations to protect individuals' privacy
            and secure sensitive data. Organizations are now required to comply
            with these regulations or face severe penalties. For example, the
            General Data Protection Regulation (GDPR) in the European Union
            imposes substantial fines for non-compliance. Taking action now to
            ensure compliance with relevant regulations will help avoid legal
            issues and maintain a competitive edge in the market.
          </div>
          <div className="blogArticleText blogParagraph">
            In an increasingly digital world, the relevance of cybersecurity
            cannot be overstated. As cyber threats continue to evolve and become
            more sophisticated, the need for proactive measures becomes
            paramount. By investing in robust security practices, staying
            informed about emerging threats, and implementing strong preventive
            measures, individuals, businesses, and organizations can mitigate
            the risks associated with cyberattacks. Taking action today will not
            only protect our valuable digital assets but also ensure a secure
            future for all stakeholders involved. Remember, cybersecurity is
            everyone's responsibility, and the time to act is now.
          </div>
        </div>
        <div className="resourcesInnerContent">
          <h1>Protecting Your Smart Factory: The Value of Cybersecurity</h1>
          <div className="authorDetails">
            <div className="innerAuthorDetails">
              <i className="blogRvbIcon"></i>
              <span>By RVBionics Blog</span>
            </div>
            <div className="articleDate">
              <p>May 8th, 2023.</p>
            </div>
          </div>
          <div className="blogImageContainer smartFactoryProtectCybersecArticleBg"></div>
          <div className="blogArticleText blogParagraph">
            Smart factories have become a reality, where machines and devices
            are interconnected and communicate with each other, resulting in
            increased efficiency, productivity, and accuracy. However, this also
            brings new challenges, particularly in the area of cybersecurity.
          </div>
          <div className="blogArticleText blogParagraph">
            Smart factories generate and collect large amounts of data, making
            them vulnerable to cyberattacks such as hacking, malware, ransomware
            and phishing. As the volume of data continues to grow, so does the
            risk of industrial espionage, intellectual property theft, and other
            malicious attacks. A single security breach can result in
            significant financial losses, production downtime, and damage to the
            company's reputation.
          </div>
          <div className="blogArticleText blogParagraph">
            For this reason, cybersecurity is a fundamental requirement for any
            Smart Factory. Manufacturers must consider it not only as a
            technical challenge but as a business issue that affects the entire
            organization. Cybersecurity strategies must be integrated into the
            company's culture, with everyone from the CEO to the factory floor
            workers playing a role in safeguarding the data.
          </div>
          <div className="blogArticleText blogParagraph">
            When deciding to digitize a manufacturing plant, cybersecurity must
            be a top priority. Companies must ensure that their cybersecurity
            policies cover all components and processes in the factory, from the
            factory floor machines to the office computers. In addition, all
            devices and software must be regularly updated and maintained to
            ensure they are protected against the latest threats.
          </div>
          <div className="blogArticleText blogParagraph">
            To protect against cyberattacks, manufacturers should also consider
            implementing a range of cybersecurity measures. For example, access
            to sensitive data should be restricted to authorized personnel only,
            with strong passwords and multi-factor authentication protocols in
            place. Encryption and firewalls should also be used to protect data
            in transit and at rest.
          </div>
          <div className="blogArticleText blogParagraph">
            Training and education of employees are also essential. Smart
            factories require a skilled workforce that is knowledgeable about
            cybersecurity threats and risks. All employees should be trained on
            the latest security measures and best practices to minimize the risk
            of human error, which can be a major cause of security breaches.
          </div>
          <div className="blogArticleText blogParagraph">
            In conclusion, cybersecurity is a crucial requirement for any Smart
            Factory. The interconnectivity of smart devices in the manufacturing
            industry has brought many benefits, but it has also increased the
            risk of cyberattacks. To protect against these threats,
            manufacturers must integrate cybersecurity strategies into their
            business processes and culture. By doing so, they can ensure the
            continuity of their operations and safeguard their data, reputation,
            and financial stability.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesContent;
