import React from "react";

import "./CommercialConsultingWindow.css";

const CommercialConsultingWindow = () => {
  return (
    <div>
      <div className="commercialConsultingWindowArea">
        <div
          className="commercialConsultingWindowInHomeLargeExclusive"
          style={{ margin: "auto" }}
        >
          <div className="commercialConsultingWindowContainer">
            <i
              className="rvb-logo-white-icon"
              style={{ height: "100px", width: "auto" }}
            ></i>
            <h1>Engineering the cybersecurity of the future</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommercialConsultingWindow;
