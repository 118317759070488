import React from "react";

import "./HeroCompany.css";

const HeroCompany = () => {
  return (
    <div>
      <div className="companyHeroArea">
        <div className="companyHeroLargeExclusive">
          <div className="companyInnerHero">
            <div className="companyHeadingContainer">
              <h1 className="companyHeadingTitle">WHO ARE WE</h1>
            </div>
            <h1 className="mainCompanyTitle">
              Working towards a more efficient world
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCompany;
