import React from "react";

import "../ResourcesContent.css";

const ResourcesArticleFour = () => {
  return (
    <div className="resourcesContentArea">
      <div className="resourcesContentLargeExclusive">
        <div className="resourcesInnerContent">
          <h1>The Human Factor in Cybersecurity</h1>
          <div className="authorDetails">
            <div className="innerAuthorDetails">
              <i className="blogRvbIcon"></i>
              <span>By RVBionics Blog</span>
            </div>
            <div className="articleDate">
              <p>August 9th, 2023.</p>
            </div>
          </div>
          <div className="blogImageContainer humanFactorArticleBg"></div>
          <div className="blogArticleText blogParagraph">
            In the modern days, industrial plants have more interconnected
            processes running than ever before, and this, has turned them
            increasingly vulnerable to multiple types of cyber threats, and all
            of us, mankind as a whole, are paying a lot of attention to the
            implementation of advanced technologies to address this issue, which
            is key to build a security-positive environment. However, all this
            means nothing if we overlook the human factor.
          </div>
          <div className="blogArticleText blogParagraph">
            The human factor refers to how industrial -and every other
            organization- personnel play a crucial role in the effectiveness of
            our defense measures and policies against cyber threats, being the
            first line of defense themselves.
          </div>
          <div className="blogArticleText blogParagraph">
            Conducting appropriate cybersecurity awareness campaigns regularly
            must be a high priority, especially in this current period of
            constant technological advances which has lead to an always evolving
            threat landscape, with the potential of causing physical damage such
            as production disruptions, safety hazards, financial losses, between
            others.
          </div>
          <div className="blogArticleText blogParagraph">
            A security-fit and conscious workforce should be qualified enough to
            identify and mitigate a considerable portion of cyber risks, such as
            social engineering attacks and unsafe practices. This is achievable
            by providing broad and regular cybersecurity training, but still
            means nothing if a company's leadership is not committed.
            Cybersecurity training must be acompanied by a comprehensive
            incident response policy and stimulated by recognition-and-reward
            policies, otherwise, you'll often find a decrease of consciousness
            after some time.
          </div>
          <div className="blogArticleText blogParagraph">
            Cybersecurity is a contrniuous process, and especially in an
            industrial context, no one can afford to lower their guard. It is
            also collaborative, a joint-arround defense is preferrable, a good
            cybersecurity training should promote learning and improve
            communication for the sake of security, it is dangerous to go alone.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesArticleFour;
